"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import Home from "@/views/home/views/Home.vue";
import API from "@ym-framework/api-utils";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import builder from "@rsql/builder";
import { emit } from "@rsql/emitter";
Vue.component(
  "async-component",
  () => import("@/views/home/views/components/DocumentTemplate.vue")
);
let DocumentTemplate = class extends Vue {
  constructor() {
    super(...arguments);
    this.isLoading = true;
    //搜索字符串
    this.searchdata = "";
    // 模板
    this.templateData = [];
    // 热门推荐
    this.hotdata = [];
    this.selectedIndex = 0;
    this.showHotData = false;
    // 类目列表
    this.categoryList = [];
    //页面数据
    this.pageData = [];
    this.lastRequestSuccessTime = 0;
    // 骨架屏数组
    this.skeletonData = [{}, {}, {}, {}, {}, {}];
  }
  mounted() {
    this.getData();
  }
  getData() {
    return __async(this, null, function* () {
      yield this.getHot();
      yield this.getTemplate();
      yield this.getSelect();
      this.isLoading = false;
    });
  }
  selectItem(categoryItem, index) {
    return __async(this, null, function* () {
      this.pageData = [];
      const categoryListItem = [];
      this.categoryList.forEach((item) => {
        if (item.parentId == "ROOT") {
          categoryListItem.push(item.id);
        }
      });
      if (categoryItem.parentId !== "ROOT") {
        this.pageData.push({
          categoryName: "\u70ED\u95E8\u63A8\u8350",
          fixed: true,
          count: this.hotdata.length,
          promptTemplates: {
            result: {
              datas: this.showHotData ? this.hotdata : this.hotdata.slice(0, 4)
            }
          }
        });
        this.templateData.forEach((item) => {
          if (categoryListItem.includes(item.categoryId)) {
            const Filteritem = this.categoryList.filter(
              (i) => i.name === item.categoryName
            );
            let arr = item.promptTemplates.result.datas;
            Filteritem[0].children.forEach((secondItem) => {
              const secondFilteritem = this.templateData.filter(
                (i) => i.categoryName === secondItem.name
              );
              arr = [...arr, ...secondFilteritem[0].promptTemplates.result.datas];
            });
            if (arr.length) {
              this.pageData.push({
                categoryName: item.categoryName,
                icon: item.icon,
                fixed: false,
                count: arr.length,
                promptTemplates: {
                  result: {
                    datas: arr.slice(0, 4)
                  }
                }
              });
            }
          }
        });
      } else {
        const apiEndpoint = this.$store.state.accountInfo.session.account ? "api://base.auth/homepage/v1/category-templates" : "api://base/homepage/v1/category-templates";
        const secondCategory = (yield API.get(`${apiEndpoint}/${categoryItem.id}/second-stage`)).data.result.datas;
        secondCategory.forEach((secondItem) => {
          if (secondItem.promptTemplates.result.datas) {
            this.pageData.push({
              categoryName: secondItem.categoryName,
              fixed: false,
              promptTemplates: {
                result: {
                  datas: secondItem.promptTemplates.result.datas
                }
              }
            });
          }
        });
      }
      this.showHotData = false;
      this.selectedIndex = index;
    });
  }
  // 更多
  selectItemmore(category) {
    this.categoryList.forEach((item, index) => {
      if (item.name == category.categoryName) {
        this.selectItem(this.categoryList[index], index);
        return;
      }
    });
  }
  // 搜索
  SearchData() {
    return __async(this, null, function* () {
      if (!this.searchdata.length) {
        this.selectItem(this.categoryList[0], 0);
        return;
      }
      let rsql = emit(
        builder.and(builder.comparison("title", "=like=", this.searchdata))
      );
      try {
        const response = (yield API.get(`api://base/homepage/v1/search-category-templates`, {
          params: {
            search: rsql
          }
        })).data.result.datas;
        this.pageData = [];
        this.pageData.push({
          fixed: false,
          categoryName: "\u641C\u7D22",
          promptTemplates: {
            result: {
              datas: response
            }
          }
        });
      } catch (error) {
        throwErrorApi.throwError(error);
      }
    });
  }
  // 回车发送消息
  suball(event) {
    event = event ? event : window.event ? window.event : "";
    var keyCode = event.keyCode;
    if (keyCode == 13) {
      event.preventDefault();
      this.SearchData();
    }
  }
  // 首页详情模板
  getTemplate() {
    return __async(this, null, function* () {
      const apiEndpoint = this.$store.state.accountInfo.session.account ? "api://base.auth/homepage/v1/category-templates" : "api://base/homepage/v1/category-templates";
      const res = yield API.get(apiEndpoint);
      this.templateData = res.data.result.datas;
    });
  }
  // 热门推荐
  getHot() {
    return __async(this, null, function* () {
      const apiEndpoint = this.$store.state.accountInfo.session.account ? "api://base.auth/homepage/v1/templates-hot-recommendation" : "api://base/homepage/v1/templates-hot-recommendation";
      const hot = yield API.get(apiEndpoint);
      this.hotdata = hot.data.datas;
    });
  }
  // 头部导航
  getSelect(parentId) {
    return __async(this, null, function* () {
      const template = (yield API.get(`api://base/homepage/v1/category`)).data.datas;
      function toTree(arr, parentId2) {
        function loop(parentId3) {
          let res = [];
          for (let i = 0; i < arr.length; i++) {
            let item = arr[i];
            if (item.parentId !== parentId3) {
              continue;
            }
            item.children = loop(item.id);
            res.push(item);
          }
          return res;
        }
        return loop(parentId2);
      }
      this.categoryList = toTree(template, "ROOT");
      this.categoryList.unshift({ name: "\u70ED\u95E8\u63A8\u8350", fixed: true, parentId: "" });
      if (this.categoryList.length > 0) {
        this.selectItem(this.categoryList[0], 0);
      }
    });
  }
  // 用户点击收藏
  collection(id, status) {
    return __async(this, null, function* () {
      const currentTime = new Date().getTime();
      const timeSinceLastSuccess = currentTime - this.lastRequestSuccessTime;
      if (timeSinceLastSuccess < 2e3) {
        return;
      }
      try {
        if (!this.$store.state.accountInfo.session.account) {
          this.$message.error("\u8BF7\u5148\u767B\u5F55");
          this._home.login();
        } else {
          yield API.post(
            `api://base.auth/v1/favorite/click-template-favorite/${id}`,
            { status: !status }
          );
          this.lastRequestSuccessTime = currentTime;
          this.$message.success(status ? "\u53D6\u6D88\u6536\u85CF" : "\u6536\u85CF\u6210\u529F");
          this.getData();
        }
      } catch (error) {
        throwErrorApi.throwError(error);
      }
      this.showHotData = false;
    });
  }
  // 模板详情
  Details(id) {
    return __async(this, null, function* () {
      this.$router.push({
        path: "/Content",
        query: {
          id
        }
      });
    });
  }
};
__decorateClass([
  Ref("home")
], DocumentTemplate.prototype, "_home", 2);
DocumentTemplate = __decorateClass([
  Component({
    components: {
      Layout,
      Home
    }
  })
], DocumentTemplate);
export {
  DocumentTemplate as default
};
