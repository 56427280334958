"use strict";
import "./configuration";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/lang";
import ElementUI from "element-ui";
import dayjs from "dayjs";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./styles.scss";
import "./assets/main.scss";
import "./assets/theme.css";
import VueLazyload from "vue-lazyload";
import { Toast } from "vant";
import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");
import "vant/lib/toast/style";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(VueTour);
Vue.use(Loading);
Vue.use(Toast);
Vue.use(VueLazyload, {
  preLoad: 1,
  // 预加载高度比例，默认 1.3
  // error: errorimage,      // 加载失败时图像的src
  // loading: loadimage,     // 正在加载时图像的src
  attempt: 1
  // 尝试次数
});
Vue.config.productionTip = false;
Vue.prototype.$dayjs = dayjs;
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});
Vue.component("async-component", () => import("@/views/home/views/components/DocumentTemplate.vue"));
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
