"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import copyText from "@/utils/copyText";
import API from "@ym-framework/api-utils";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { accountInfoAPI } from "@/shared/api/account-info/account-info.api";
let InstructionGenerator = class extends Vue {
  constructor() {
    super(...arguments);
    this.textarea1 = "";
    this.gptBRTR = "";
    this.total = 0;
    this.currentPage = 1;
    this.dialogVisible = false;
    // isloading = false;
    this.showMessage = false;
    // 选中的历史记录
    this.selectedIndex = null;
    //是否可以发生消息请求
    this.isPost = true;
    // 是否显示积分不足弹窗
    this.isConfirmBox = false;
    // 历史记录
    this.historydata = [];
  }
  // submit() {
  //   this.isloading = true;
  //   this.showMessage = true;
  // }
  mounted() {
    const _token = localStorage.getItem("token");
    if (_token) {
      this.getHistory();
    }
  }
  updatemsg() {
    this.$nextTick(() => {
      let div = document.getElementById("message-info");
      div.scrollTop = div.scrollHeight;
    });
  }
  // 打开积分不足弹窗
  openConfirmBox() {
    if (this.isConfirmBox) {
      return;
    }
    this.isConfirmBox = true;
    this.$confirm(`\u60A8\u7684\u70B9\u6570\u4E0D\u8DB3\uFF0C\u8BF7\u5145\u503C\u540E\u53EF\u4EE5\u7EE7\u7EED\u4F7F\u7528`, "\u63D0\u793A", {
      confirmButtonText: "\u5145\u503C",
      cancelButtonText: "\u53D6\u6D88",
      type: "warning",
      roundButton: true,
      customClass: "messageClass"
    }).then(() => {
      this._layout.goGptPackage();
    }).catch(() => {
    }).finally(() => {
      this.isConfirmBox = false;
    });
  }
  //sse
  sseMsg(sendmsg) {
    if (this.$store.state.accountInfo.credits.totalRemainingMessageCredit <= 0) {
      this.openConfirmBox();
      return;
    }
    if (!this.isPost) {
      return;
    }
    if (!this.$store.state.accountInfo.session.account) {
      this._layout.login();
      this.$message.error("\u8BF7\u5148\u767B\u5F55");
    } else {
      let _a;
      this.textarea1 = "";
      this.isPost = false;
      let opsendmsg = () => __async(this, null, function* () {
        this.isPost = true;
        const credits = yield accountInfoAPI.getCredits();
        this.$store.commit("accountInfo/updateCredits", credits);
      });
      let getssemsg = () => {
        this.isPost = false;
      };
      let updatessemsg = (msg) => {
        this.textarea1 += msg;
        this.updatemsg();
      };
      fetchEventSource(
        `https://gateway.kuajingvs.com/ai/prompt/v1/instruction-generation`,
        {
          method: "POST",
          openWhenHidden: true,
          body: JSON.stringify({
            input: sendmsg
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token")
          },
          onopen() {
            return __async(this, null, function* () {
              getssemsg();
            });
          },
          onmessage(msg) {
            let msgdata = JSON.parse(msg.data);
            if (msgdata.content !== "[DONE]") {
              updatessemsg(msgdata.content);
            }
          },
          onclose() {
            opsendmsg();
          }
        }
      );
    }
  }
  // 历史记录
  getHistory(index) {
    return __async(this, null, function* () {
      const history = yield API.get(`api://base.auth/v1/instruction-generation`, {
        params: {
          page: index,
          size: 10
        }
      });
      this.historydata = history.data.result.datas;
      this.total = history.data.page.total;
      this.selectedIndex = index;
    });
  }
  handleCurrentChange(currentPage) {
    this.currentPage = currentPage;
    this.getHistory(currentPage);
  }
  // 显示历史记录
  showHistory(id) {
    const historyItem = this.historydata.find((item) => item.id === id);
    if (historyItem) {
      this.textarea1 = historyItem.response;
      this.dialogVisible = false;
    }
  }
  handleRowClick(row) {
    this.selectedIndex = row.id;
    this.showHistory(row.id);
  }
  // 复制
  copyText(type) {
    copyText.copyText(type);
  }
  // 收藏
  collect() {
  }
};
__decorateClass([
  Ref("layout")
], InstructionGenerator.prototype, "_layout", 2);
InstructionGenerator = __decorateClass([
  Component({
    components: {
      Layout
    }
  })
], InstructionGenerator);
export {
  InstructionGenerator as default
};
