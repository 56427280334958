"use strict";
export class SpeechService {
  constructor(lang, continuous, interimResults) {
    this.isListening = false;
    this.isFinal = false;
    this.result = "";
    //recognition配置
    this.lang = "";
    this.lang = lang;
    this.continuous = continuous;
    this.interimResults = interimResults;
  }
  createRecognition() {
    try {
      this.recognition = new window.webkitSpeechRecognition();
      this.recognition.continuous = this.continuous;
      this.recognition.interimResults = this.interimResults;
      this.recognition.lang = this.lang;
      this.recognition.onstart = () => {
        this.isFinal = false;
      };
      this.recognition.onresult = (event) => {
        const transcript = Array.from(event.results).map((result) => {
          this.isFinal = result.isFinal;
          return result[0];
        }).map((result) => result.transcript).join("");
        this.result = transcript;
        this.error = void 0;
      };
      this.recognition.onerror = (event) => {
        this.error = "Speech recognition error detected: " + event.error;
      };
      this.recognition.onend = () => {
        this.isListening = false;
      };
    } catch (err) {
      console.log(err);
    }
  }
  // 设置开始监听函数
  start() {
    this.isListening = true;
    this.recognition.start();
  }
  //  // 停止监听
  stop() {
    this.recognition.stop();
    this.isListening = false;
  }
  // 检查浏览器是否支持
  // if(isSupported) {
  //   watch(isListening, () => {
  //     if (isListening.value)
  //       recognition.start()
  //     else
  //       recognition.stop()
  //   })
  // }
}
