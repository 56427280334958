"use strict";
import Clipboard from "clipboard";
import { Toast } from "vant";
export default {
  copyText: (type) => {
    const clipboard = new Clipboard(type);
    clipboard.on("success", (e) => {
      Toast("\u5DF2\u590D\u5236\u5230\u526A\u8D34\u677F");
      clipboard.destroy();
    });
    clipboard.on("error", (e) => {
      Toast("\u590D\u5236\u5931\u8D25");
      clipboard.destroy();
    });
  }
};
