"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue, Ref } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
let Painting = class extends Vue {
  constructor() {
    super(...arguments);
    this.menuactive = "CreatePainting";
    this.data = [];
    this.searchdata = "";
    this.show = true;
    // 是否显示pc
    this.isShowPc = true;
    //获取屏幕宽度
    this.screenWidth = document.body.clientWidth;
    this.menuItems = [
      {
        title: "\u521B\u5EFA\u7ED8\u753B",
        imgSrc: require("../../../assets/images/icon-huihua.png"),
        to: "/create-painting"
      },
      {
        title: "\u6211\u7684\u7ED8\u753B",
        icon: "el-icon-star-off",
        to: "/my-painting"
      },
      {
        title: "\u753B\u5ECA",
        imgSrc: require("../../../assets/images/icon-hualang.png"),
        to: "/Gallery"
      }
    ];
  }
  handleResize() {
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth > 765) {
      this.isShowPc = true;
    } else {
      this.isShowPc = false;
    }
  }
  login() {
    this._layout.login();
  }
  //购买套餐
  goGptPackage() {
    this._layout.goGptPackage();
  }
  active(menuItem) {
    return this.$route.path === menuItem.to;
  }
};
__decorateClass([
  Ref("layout")
], Painting.prototype, "_layout", 2);
Painting = __decorateClass([
  Component({
    components: {
      Layout
    }
  })
], Painting);
export {
  Painting as default
};
