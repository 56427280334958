import { render, staticRenderFns } from "./DocumentTemplate.vue?vue&type=template&id=73cd3fca&scoped=true"
import script from "./DocumentTemplate.vue?vue&type=script&lang=ts"
export * from "./DocumentTemplate.vue?vue&type=script&lang=ts"
import style0 from "./DocumentTemplate.vue?vue&type=style&index=0&id=73cd3fca&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73cd3fca",
  null
  
)

export default component.exports