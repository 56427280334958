"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import copyText from "@/utils/copyText";
let customTemplate = class extends Vue {
  constructor() {
    super(...arguments);
    this.dialogVisible = false;
    this.activeName = 1;
    this.qqNumber = "3684329568";
  }
  // 复制QQ
  copyQQ(type) {
    copyText.copyText(type);
  }
};
customTemplate = __decorateClass([
  Component({
    components: {
      Layout
    }
  })
], customTemplate);
export {
  customTemplate as default
};
