"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import Home from "@/views/home/views/Home.vue";
let Mycollection = class extends Vue {
  constructor() {
    super(...arguments);
    this.collectiondata = [];
    this.pageNumber = 1;
    this.total = 0;
    this.currentPage = 1;
  }
  mounted() {
    this.Collection();
  }
  // 我的收藏
  Collection(index) {
    return __async(this, null, function* () {
      const collection = yield API.get(
        `api://base.auth/v1/favorite/my-favorites-prompt-templates`,
        {
          params: {
            page: index,
            size: 12
          }
        }
      );
      this.total = collection.data.page.total;
      this.collectiondata = collection.data.result.datas;
    });
  }
  // 模板详情
  Details(id) {
    return __async(this, null, function* () {
      this.$router.push({
        path: "/Content",
        query: {
          id
        }
      });
    });
  }
  // 分页
  handleCurrentChange(currentPage) {
    this.currentPage = currentPage;
    this.Collection(currentPage);
  }
};
Mycollection = __decorateClass([
  Component({
    components: {
      Home
    }
  })
], Mycollection);
export {
  Mycollection as default
};
