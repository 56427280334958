"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import Editcategory from "./Editcategory.vue";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
let SortManagement = class extends Vue {
  constructor() {
    super(...arguments);
    this.isEdit = false;
    //搜索
    this.searchdata = "";
    this.activeNames = [];
    //全部分类
    this.category = [];
    //分类树形结构
    this.categoryTree = [];
    //编辑类目对象
    this.editData = {};
  }
  mounted() {
    return __async(this, null, function* () {
      this.getCategory();
    });
  }
  getCategory() {
    return __async(this, null, function* () {
      let res = (yield API.get(`api://base.admin/admin/v1/category`)).data.datas;
      this.category = res;
      function toTree(arr, parentId) {
        function loop(parentId2) {
          let res2 = [];
          for (let i = 0; i < arr.length; i++) {
            let item = arr[i];
            if (item.parentId !== parentId2) {
              continue;
            }
            item.children = loop(item.id);
            res2.push(item);
          }
          return res2;
        }
        return loop(parentId);
      }
      this.categoryTree = toTree(res, "ROOT");
    });
  }
  delCategory(id) {
    return __async(this, null, function* () {
      try {
        yield API.delete(`api://base.admin/admin/v1/category/${id}`);
        this.getCategory();
      } catch (error) {
        throwErrorApi.throwError(error);
      }
    });
  }
  addRootCategory() {
    this.editData = {
      type: "add",
      parentId: "ROOT",
      parentName: "ROOT"
    };
    this.isEdit = !this.isEdit;
  }
  addSecondCategory(data) {
    this.editData = {
      type: "add",
      parentId: data.id,
      parentName: data.name
    };
    this.isEdit = !this.isEdit;
  }
  changeCategory(data) {
    if (data.parentId == "ROOT") {
    }
    const categoryItem = this.category.filter(
      (item) => item.id === data.parentId
    );
    this.editData = {
      type: "change",
      parentId: data.parentId,
      parentName: data.parentId == "ROOT" ? "ROOT" : categoryItem[0].name,
      name: data.name,
      id: data.id,
      icon: data.icon
    };
    this.isEdit = !this.isEdit;
  }
  exitEdit() {
    this.isEdit = !this.isEdit;
    this.getCategory();
  }
};
SortManagement = __decorateClass([
  Component({
    components: { Editcategory }
  })
], SortManagement);
export {
  SortManagement as default
};
