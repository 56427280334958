"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import UiForm, {
  UiFormController,
  UiFormStatus
} from "@/components/form/UiForm.vue";
import API from "@ym-framework/api-utils";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import UiSubmit from "@/components/form/UiSubmit.vue";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.account = "";
    this.password = "";
  }
}
let Adminlogin = class extends Vue {
  constructor() {
    super(...arguments);
    this.controller = new FormController();
    this.circleUrl = "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png";
  }
  submit() {
    return __async(this, null, function* () {
      var _a;
      try {
        this.controller.status = UiFormStatus.LOADING;
        const loginResponse = (yield API.post(`api://base/admin/v1/admin-login`, this.controller)).data;
        localStorage.setItem("admintoken", (_a = loginResponse.token) != null ? _a : "");
        this.$router.push("/admin");
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.controller.status = UiFormStatus.COMPLETE;
      }
    });
  }
};
Adminlogin = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit
    }
  })
], Adminlogin);
export {
  Adminlogin as default
};
