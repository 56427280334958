"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import GptPackage from "@/views/gptsession/views/dialog/GptPackage.vue";
import { accountInfoAPI } from "@/shared/api/account-info/account-info.api";
import API from "@ym-framework/api-utils";
import ResetpasswordDialog from "@/views/auth/views/dialog/ResetpasswordDialog.vue";
import copyText from "@/utils/copyText";
import VersionUpdatingDialog from "./VersionUpdatingDialog.vue";
let Layout = class extends Vue {
  constructor() {
    super(...arguments);
    this.routerPath = this.$route.path;
    this.selectedVersion = "gpt-3.5-turbo";
    this.menuactive = "";
    //获取屏幕宽度
    this.screenWidth = document.body.clientWidth;
    this.isShowPc = true;
    this.version = "";
    this.versionData = [];
    this.isShowMobileNav = false;
    this.qqNumber = "3684329568";
    this.languageoptions = [
      {
        value: "Chinese",
        label: "\u4E2D\u6587"
      },
      {
        value: "English",
        label: "\u82F1\u6587"
      },
      {
        value: "Spanish",
        label: "\u897F\u73ED\u7259\u8BED"
      },
      {
        value: "German",
        label: "\u5FB7\u8BED"
      },
      {
        value: "French",
        label: "\u6CD5\u8BED"
      },
      {
        value: "Italian",
        label: "\u610F\u5927\u5229\u8BED"
      },
      {
        value: "Japanese",
        label: "\u65E5\u8BED"
      },
      {
        value: "Dutch",
        label: "\u8377\u5170\u8BED"
      },
      {
        value: "Polish",
        label: "\u6CE2\u5170\u8BED"
      },
      {
        value: "Swedish",
        label: "\u745E\u5178\u8BED"
      },
      {
        value: "Korean",
        label: "\u97E9\u8BED"
      },
      {
        value: "Portuguese",
        label: "\u8461\u8404\u7259\u8BED"
      },
      {
        value: "Thai",
        label: "\u6CF0\u8BED"
      },
      {
        value: "Intelligent",
        label: "\u667A\u80FD"
      }
    ];
    this.topmenuItems = [
      {
        title: "GPT\u5BF9\u8BDD",
        index: "1",
        to: "/gptsession"
      },
      {
        title: "GPT\u6307\u4EE4",
        index: "2",
        to: "/gptinstruction"
      },
      {
        title: "GPT\u6307\u4EE4\u751F\u6210\u5668",
        index: "3",
        to: "/instruction-generator"
      },
      // {
      //   title: "应用模板",
      //   index: "4",
      //   to: "/document-template",
      // },
      {
        title: "AI\u7ED8\u753B",
        index: "5",
        to: "/create-painting"
      }
    ];
  }
  // 主题切换
  changeTheme() {
    let theme = this.$store.state.templateInfo.templateOptions.theme === "light" ? "light" : "dark";
    this.$store.commit("templateInfo/updateTheme", theme);
    document.documentElement.setAttribute("data-theme", theme);
  }
  created() {
    let savedTheme = localStorage.getItem("theme");
    this.$store.commit("templateInfo/updateTheme", savedTheme);
    document.documentElement.setAttribute("data-theme", savedTheme);
  }
  mounted() {
    return __async(this, null, function* () {
      const main = require("../../package.json");
      this.version = main.version.replace(/\+/g, ".");
      const _token = localStorage.getItem("token");
      if (_token) {
        document.cookie = `chatmofa_token=${_token};max-age=86400`;
        const credits = yield accountInfoAPI.getCredits();
        this.$store.commit("accountInfo/updateCredits", credits);
        let isSigned = (yield API.get("api://base.auth/v1/sign-in")).data;
        this.$store.commit("accountInfo/updateSigned", isSigned);
        if (isSigned !== this.$store.state.accountInfo.isSigned) {
          this.$store.commit("accountInfo/updateSigned", isSigned);
        }
      } else {
        document.cookie = "chatmofa_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      }
      this.handleResize();
      window.addEventListener("resize", this.handleResize);
      this.selectVersion("gpt-3.5-turbo");
    });
  }
  //页面umount时，移除监听事件
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  }
  //屏幕宽度
  handleResize() {
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth > 765) {
      this.isShowPc = true;
    } else {
      this.isShowPc = false;
    }
  }
  selectMobileNav(path) {
    this.isShowMobileNav = false;
    this.$router.push(path);
    this.$forceUpdate();
  }
  showMobileNav() {
    this.isShowMobileNav = !this.isShowMobileNav;
  }
  maskAccount() {
    const visiblePart = this.$store.state.accountInfo.session.account.substring(
      0,
      3
    );
    const hiddenPart = "*".repeat(4);
    const lastPart = this.$store.state.accountInfo.session.account.substring(7);
    return visiblePart + hiddenPart + lastPart;
  }
  goBack() {
    this.$router.go(-1);
  }
  //签到
  signin() {
    return __async(this, null, function* () {
      try {
        yield API.post("api://base.auth/v1/sign-in");
        this.$store.commit("accountInfo/updateSigned", true);
        this.$toast("\u7B7E\u5230\u6210\u529F");
        const credits = yield accountInfoAPI.getCredits();
        this.$store.commit("accountInfo/updateCredits", credits);
      } catch (e) {
        this.$toast("\u7B7E\u5230\u5931\u8D25");
      }
    });
  }
  copyQQ(type) {
    if (this.isShowPc) {
      navigator.clipboard.writeText("3684329568").then(() => {
        this.$toast("\u5DF2\u590D\u5236\u5230\u526A\u8D34\u677F");
      }).catch((error) => {
        this.$toast("\u590D\u5236\u5931\u8D25");
      });
    } else {
      copyText.copyText(type);
    }
  }
  customTemplate() {
    this.$router.push("/custom-template");
  }
  // 跳转到收藏页面
  goMycollection() {
    this.$router.push("/my-collection");
  }
  //购买套餐
  goGptPackage() {
    if (this.screenWidth > 765) {
      this._gptPackage.open();
    } else {
      this.$router.push("/mobile-gptpackage");
    }
  }
  // 订单中心
  goOrder() {
    this.$router.push("/order-center");
  }
  // 跳转到用户邀请
  goUserInvitation() {
    this.$router.push("/user-invitation");
  }
  // 创建绘画
  goCreatePainting() {
    this.$router.push("/create-painting");
  }
  // 我的绘画
  goMyPainting() {
    this.$router.push("/my-painting");
  }
  // 画廊
  goGallery() {
    this.$router.push("/Gallery");
  }
  // // 重置密码
  // resetpassword() {
  //   this._resetpassworddialog.open();
  // }
  //退出
  logOut() {
    localStorage.removeItem("token");
    this.$store.commit("accountInfo/updateSession", {});
    this.$store.commit("accountInfo/updateSigned", false);
    localStorage.removeItem("user");
    this.$router.replace("/");
  }
  selectVersion(version) {
    this.selectedVersion = version;
    this.$store.commit("templateInfo/updateTemplateOptions", {
      gptmodel: version
    });
  }
  login() {
    this._logindialog.authType = "login";
    this._logindialog.open();
  }
  // 打开弹窗
  openDialog(type) {
    this._logindialog.authType = type;
    this._logindialog.open();
  }
};
__decorateClass([
  Ref("logindialog")
], Layout.prototype, "_logindialog", 2);
__decorateClass([
  Ref("resetpassworddialog")
], Layout.prototype, "_resetpassworddialog", 2);
__decorateClass([
  Ref("gptPackage")
], Layout.prototype, "_gptPackage", 2);
__decorateClass([
  Ref("versionUpdatingDialog")
], Layout.prototype, "_versionUpdatingDialog", 2);
Layout = __decorateClass([
  Component({
    components: {
      LoginDialog: () => import("@/views/home/views/dialog/LoginDialog.vue"),
      ResetpasswordDialog,
      GptPackage,
      VersionUpdatingDialog
    }
  })
], Layout);
export {
  Layout as default
};
