"use strict";
export class ResetService {
  /**
   * 校验两次密码是否一致
   * @param confirmPassword
   * @param password
   * @param callback
   */
  checkConfirmPassword(confirmPassword, password, callback) {
    if (confirmPassword && confirmPassword !== password) {
      callback(new Error("\u5BC6\u7801\u4E0D\u4E00\u81F4"));
      return false;
    }
    callback();
    return true;
  }
}
