"use strict";
import Vue from "vue";
import VueRouter from "vue-router";
import { AuthAccess, GuestAccess, AdminAccess } from "@/access-rules";
import Home from "@/views/home/views/Home.vue";
import Admin from "@/views/admin/views/Admin.vue";
import TemplateManagement from "@/views/admin/views/components/TemplateManagement.vue";
import Adminlogin from "@/views/admin/views/components/Adminlogin.vue";
import SortManagement from "@/views/admin/views/components/SortManagement.vue";
import Mycollection from "@/views/home/views/components/Mycollection.vue";
import DocumentTemplate from "@/views/home/views/components/DocumentTemplate.vue";
import UserInvitation from "@/views/home/views/components/UserInvitation.vue";
import Gptinstruction from "@/views/gptInstruction/views/Gptinstruction.vue";
import InstructionGenerator from "@/views/instructionGenerator/views/InstructionGenerator.vue";
import Content from "@/views/home/views/components/Content.vue";
import KuaJingOauth from "@/views/authorization/KuaJingOauth.vue";
import Gptsession from "@/views/gptsession/views/components/Gptsession.vue";
import customTemplate from "@/views/home/views/components/customTtemplate.vue";
import MobileGptPackage from "@/views/gptsession/views/dialog/MobileGptPackage.vue";
import OrderCenter from "@/views/home/views/components/OrderCenter.vue";
import Gallery from "@/views/aiPainting/views/components/Gallery.vue";
import MyPainting from "@/views/aiPainting/views/components/MyPainting.vue";
import CreatePainting from "@/views/aiPainting/views/components/CreatePainting.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: "/gptsession",
    meta: {
      title: "Home"
    },
    component: Home
  },
  // {
  //   path: '/invite-register',
  //   name: 'invite-register',
  //   meta: {
  //     title: '注册',
  //   },
  //   component: InviteRegister
  // },
  {
    path: "/mobile-gptpackage",
    name: "mobile-gptpackage",
    meta: {
      title: "\u79FB\u52A8\u7AEF\u652F\u4ED8"
    },
    component: MobileGptPackage
  },
  {
    path: "/gptsession",
    name: "gptsession",
    meta: {
      title: "Chat\u9B54\u6CD5"
    },
    component: Gptsession
  },
  {
    path: "/custom-template",
    name: "custom-template",
    meta: {
      title: "\u5B9A\u5236\u5316\u6A21\u677F"
    },
    component: customTemplate
  },
  {
    path: "/kjoauth",
    name: "kjoauth",
    meta: {
      title: "\u8DE8\u5883\u536B\u58EB\u767B\u5F55"
    },
    component: KuaJingOauth
  },
  {
    path: "/document-template",
    name: "Document-Template",
    meta: {
      title: "\u5E94\u7528\u6A21\u677F"
      // menuactive: 1,
    },
    // component: DocumentTemplate
    // component: r => require.ensure([], () => r(require('@/views/home/views/components/DocumentTemplate.vue')), 'document-template')
    component: DocumentTemplate
  },
  {
    path: "/my-collection",
    name: "my-collection",
    meta: {
      access: new AuthAccess(),
      title: "\u6211\u7684\u6536\u85CF"
      // menuactive: 2,
    },
    component: Mycollection
  },
  {
    path: "/user-invitation",
    name: "UserInvitation",
    meta: {
      title: "\u7528\u6237\u9080\u8BF7"
    },
    component: UserInvitation
  },
  {
    path: "/order-center",
    name: "OrderCenter",
    meta: {
      title: "\u8BA2\u5355\u4E2D\u5FC3"
    },
    component: OrderCenter
  },
  {
    path: "/Gptinstruction",
    name: "Gptinstruction",
    meta: {
      title: "GPT\u6307\u4EE4",
      menuactive: 3
    },
    component: Gptinstruction
  },
  {
    path: "/instruction-generator",
    name: "instruction-generator",
    meta: {
      title: "GPT\u6307\u4EE4\u751F\u6210\u5668",
      menuactive: 4
    },
    component: InstructionGenerator
  },
  {
    path: "/content",
    name: "Content",
    meta: {
      title: "\u6A21\u677F\u8BE6\u60C5",
      menuactive: 5
      // keepAlive:false
    },
    component: Content
  },
  {
    path: "/create-painting",
    name: "create-painting",
    meta: {
      title: "\u521B\u5EFA\u7ED8\u753B",
      menuactive: 6
      // keepAlive:false
    },
    component: CreatePainting
  },
  {
    path: "/my-painting",
    name: "my-painting",
    meta: {
      title: "\u6211\u7684\u7ED8\u753B",
      menuactive: 6
      // keepAlive:false
    },
    component: MyPainting
  },
  {
    path: "/Gallery",
    name: "Gallery",
    meta: {
      title: "\u753B\u5ECA",
      menuactive: 6
      // keepAlive:false
    },
    component: Gallery
  },
  {
    path: "/admin",
    name: "admin",
    redirect: "/admin/template-management",
    meta: {
      access: new AdminAccess(),
      title: "Admin"
      // menuactive: 6,
    },
    component: Admin,
    children: [
      {
        path: "template-management",
        name: "template-management",
        meta: {
          access: new AdminAccess(),
          title: "\u6A21\u677F\u7BA1\u7406"
          // menuactive: 6,
        },
        component: TemplateManagement
      },
      {
        path: "sort-management",
        name: "sort-management",
        meta: {
          access: new AdminAccess(),
          title: "\u5206\u7C7B\u7BA1\u7406"
          // menuactive: 6,
        },
        component: SortManagement
      },
      // {
      //   path: 'edittemplate',
      //   name: 'Edittemplate',
      //   meta: {
      //     access: new AdminAccess(),
      //     title: "Edittemplate",
      //   },
      //   component: Edittemplate
      // },
      // {
      //   path: 'editcategory',
      //   name: 'Editcategory',
      //   meta: {
      //     access: new AdminAccess(),
      //     title: "Editcategory",
      //   },
      //   component: Editcategory
      // },
      {
        path: "adminlogin",
        name: "Adminlogin",
        meta: {
          // access: new AuthAccess(),
          title: "\u540E\u53F0\u767B\u5F55"
        },
        component: Adminlogin
      }
    ]
  },
  {
    // 当没有匹配到正确路由的时候，匹配notOpen组件
    path: "*",
    redirect: "/gptsession"
    // meta: {
    //   footShow: false // true显示，false隐藏
    // }
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  var _a, _b;
  let _access = new GuestAccess();
  if ((_a = to.meta) == null ? void 0 : _a.access) {
    _access = to.meta.access;
  }
  if ((_b = to.meta) == null ? void 0 : _b.title) {
    document.title = to.meta.title;
  }
  if (to.query.invite_code) {
  }
  _access.next(to, from, next);
});
export default router;
