"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref } from "vue-property-decorator";
import UiSubmit from "@/components/form/UiSubmit.vue";
import InputPassword from "@/shared/components/input-password/InputPassword.vue";
import InputVerifyCode from "@/shared/components/input-verify-code/InputVerifyCode.vue";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import API from "@ym-framework/api-utils";
import { Debounce } from "vue-debounce-decorator";
import { accountInfoAPI } from "@/shared/api/account-info/account-info.api";
import store from "@/store";
import UiForm, {
  UiFormController,
  UiFormStatus
} from "@/components/form/UiForm.vue";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.account = "";
    this.verifyCode = "";
    this.inviteCode = "";
    this.password = "";
  }
}
let RegisterDialog = class extends Vue {
  constructor() {
    super(...arguments);
    // 登录表单controller
    this.controller = new FormController();
    // 条形码
    this.captcha = "";
    this.code = "";
    // 是否禁用输入框
    this.isDisabled = false;
    this.sessionkey = "";
    this.lastRequestSuccessTime = 0;
    // 手机号校验规则
    this.registerAccountRules = [
      {
        required: true,
        message: "\u8BF7\u8F93\u5165\u624B\u673A\u53F7",
        trigger: "blur"
      },
      {
        pattern: /^1[3456789]\d{9}$/,
        message: "\u624B\u673A\u53F7\u683C\u5F0F\u9519\u8BEF\uFF01",
        trigger: "blur"
      }
    ];
    // 密码校验规则
    this.registerPasswordRules = [
      {
        required: true,
        message: "\u8BF7\u8F93\u5165\u5BC6\u7801",
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[A-Za-z])(?=.*\d)[^\u4e00-\u9fa5 ]+$/,
        message: "\u8BF7\u8F93\u5165\u6B63\u786E\u5BC6\u7801\u683C\u5F0F\uFF0C\u5305\u62EC\u6570\u5B57\u548C\u5B57\u6BCD",
        trigger: "blur"
      },
      {
        min: 6,
        max: 24,
        message: "\u5BC6\u7801\u957F\u5EA6\u4F4D6-24\u4F4D",
        trigger: "blur"
      }
    ];
    // 验证码校验规则
    this.registerVerifyCodeRules = [
      {
        required: true,
        message: "\u8BF7\u8F93\u5165\u77ED\u4FE1\u9A8C\u8BC1\u7801",
        trigger: "blur"
      }
    ];
    this.registerInviteRules = [
      {
        required: true,
        message: "\u8BF7\u8F93\u5165\u9080\u8BF7\u7801",
        trigger: "blur"
      }
    ];
  }
  mounted() {
    this.getCaptcha();
    if (this.$route.query.invite_code) {
      this.controller.inviteCode = this.$route.query.invite_code;
      this.isDisabled = true;
    } else {
      this.controller.inviteCode = "";
      this.isDisabled = false;
    }
  }
  // 校验手机号
  validatePhone() {
    this._form._form.validateField("account", (errMsg) => {
      if (errMsg) {
        throw Error();
      }
    });
  }
  // 条形码
  getCaptcha() {
    return __async(this, null, function* () {
      const currentTime = new Date().getTime();
      const timeSinceLastSuccess = currentTime - this.lastRequestSuccessTime;
      if (timeSinceLastSuccess < 1e3) {
        return;
      }
      this;
      const response = yield API.get(`api://base/v1/captcha`, {
        responseType: "blob"
      });
      this.sessionkey = response.headers["session-key"];
      this.lastRequestSuccessTime = currentTime;
      var blob = new Blob([response.data], { type: "image/png" });
      this.captcha = URL.createObjectURL(blob);
    });
  }
  submit() {
    return __async(this, null, function* () {
      var _a;
      try {
        this.controller.status = UiFormStatus.LOADING;
        this.controller.inviteCode = this.$route.query.invite_code || this.controller.inviteCode;
        const res = (yield API.post(`api://base/v1/register`, this.controller)).data;
        localStorage.setItem("token", (_a = res.token) != null ? _a : "");
        this.userinfo = yield accountInfoAPI.getAccountInfo();
        this.credits = yield accountInfoAPI.getCredits();
        store.commit("accountInfo/updateSession", this.userinfo);
        store.commit("accountInfo/updateCredits", this.credits);
        this.$message.success("\u6CE8\u518C\u6210\u529F");
        this.$router.push("/");
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.controller.status = UiFormStatus.COMPLETE;
        this.controller = new FormController();
        this.controller.inviteCode = this.$route.query.invite_code;
      }
    });
  }
  login() {
    this.$emit("returnLogin");
  }
};
__decorateClass([
  Ref("loginForm")
], RegisterDialog.prototype, "_form", 2);
__decorateClass([
  Debounce(400)
], RegisterDialog.prototype, "submit", 1);
RegisterDialog = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit,
      InputVerifyCode,
      InputPassword
    }
  })
], RegisterDialog);
export {
  RegisterDialog as default
};
