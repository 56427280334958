"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Watch } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import { toDataURL } from "qrcode";
import { Debounce } from "vue-debounce-decorator";
import { accountInfoAPI } from "@/shared/api/account-info/account-info.api";
let GptPackage = class extends Vue {
  constructor() {
    super(...arguments);
    //获取屏幕宽度
    this.screenWidth = document.body.clientWidth;
    this.isShowPc = true;
    this.dialogVisible = false;
    this.payCodeData = "";
    this.payPrice = "";
    this.currentPackageCode = "BLACKIRON_MESSAGE_CREDIT_COMBO";
    // packageList = [
    //   {
    //     name: "黑铁套餐",
    //     code: "BLACKIRON_MESSAGE_CREDIT_COMBO",
    //     price: 3,
    //     dianshu: 100,
    //   },
    //   {
    //     name: "青铜套餐",
    //     code: "BRONZE_MESSAGE_CREDIT_COMBO",
    //     price: 18,
    //     dianshu: 600,
    //   },
    //   {
    //     name: "白银套餐",
    //     code: "SILVER_MESSAGE_CREDIT_COMBO",
    //     price: 48,
    //     dianshu: 2000,
    //   },
    //   {
    //     name: "钻石套餐",
    //     code: "DIAMOND_MESSAGE_CREDIT_COMBO",
    //     price: 138,
    //     dianshu: 6000,
    //   },
    // ];
    this.packageList = [];
    this.paymentKey = "WECHAT";
    this.paymentList = [];
  }
  currentPackageCodeChanged(val, oldVal) {
    this.getPrice();
  }
  handleResize() {
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth > 765) {
      this.isShowPc = true;
    } else {
      this.isShowPc = false;
      this.dialogVisible = false;
    }
  }
  mounted() {
    return __async(this, null, function* () {
      this.handleResize();
      window.addEventListener("resize", this.handleResize);
      const _token = localStorage.getItem("token");
      if (_token) {
        this.paymentList = (yield API.get(`api://base.auth/v1/payment-keys`)).data.datas;
        this.packageList = (yield API.get(`api://base.auth/v1/products`)).data.datas;
      }
    });
  }
  selectPackage(item) {
    this.currentPackageCode = item.code;
  }
  selectPayment(pay) {
    this.paymentKey = pay;
  }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.getPrice();
      this.dialogVisible = true;
    });
  }
  // 关闭弹窗
  close() {
    return __async(this, null, function* () {
      this.dialogVisible = false;
      this.payCodeData = "";
      const credits = yield accountInfoAPI.getCredits();
      this.$store.commit("accountInfo/updateCredits", credits);
    });
  }
  getPrice() {
    return __async(this, null, function* () {
      this.payPrice = (yield API.post(`api://base.auth/v1/compute`, {
        products: [
          {
            code: this.currentPackageCode,
            quantity: 1
          }
        ]
      })).data.totalPrice;
    });
  }
  payOrder() {
    return __async(this, null, function* () {
      let url = (yield API.post(`api://base.auth/v1/pay-order`, {
        paymentKey: this.paymentKey,
        products: [
          {
            code: this.currentPackageCode,
            quantity: 1
          }
        ]
      })).data.url;
      this.getQrcode(url);
    });
  }
  getQrcode(url) {
    let qrCodeData = "";
    const options = {
      width: 150,
      // 二维码的宽度
      height: 150,
      // 二维码的高度
      color: {
        dark: "#000000",
        light: "#ffffff"
      }
    };
    toDataURL(url, options, (err, dataURL) => {
      if (err) {
        console.error(err);
        return;
      }
      qrCodeData = dataURL;
    });
    this.payCodeData = qrCodeData;
  }
};
__decorateClass([
  Watch("currentPackageCode", { deep: true, immediate: false })
], GptPackage.prototype, "currentPackageCodeChanged", 1);
__decorateClass([
  Debounce(500)
], GptPackage.prototype, "payOrder", 1);
GptPackage = __decorateClass([
  Component({
    components: {}
  })
], GptPackage);
export {
  GptPackage as default
};
