"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import Edittemplate from "./Edittemplate.vue";
import { APIDataSource } from "@/components/grid/data-source";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import API from "@ym-framework/api-utils";
import builder from "@rsql/builder";
import { emit } from "@rsql/emitter";
let TemplateManagement = class extends Vue {
  constructor() {
    super(...arguments);
    this.isEdit = false;
    this.searchdata = "";
    // 选中的列表行对象数组
    this.robotqaSelection = [];
    this.editData = {};
    this.categoryTree = [];
    this.category = [];
    this.gridController = new GridController(
      new APIDataSource(`api://base.admin/admin/v1/templates`)
    );
  }
  mounted() {
    return __async(this, null, function* () {
      let res = (yield API.get(`api://base.admin/admin/v1/category`)).data.datas;
      this.category = res;
      function toTree(arr, parentId) {
        function loop(parentId2) {
          let res2 = [];
          for (let i = 0; i < arr.length; i++) {
            let item = arr[i];
            if (item.parentId !== parentId2) {
              continue;
            }
            item.children = loop(item.id);
            res2.push(item);
          }
          return res2;
        }
        return loop(parentId);
      }
      this.categoryTree = toTree(res, "ROOT");
    });
  }
  // 获取选中的列表
  handleSelectionChange(val) {
    this.robotqaSelection = val;
  }
  getCategoryName(categoryId, parentId, type) {
    if (type == "first") {
      if (parentId == "ROOT") {
        const categoryItem2 = this.category.filter(
          (item) => item.id == categoryId
        );
        return categoryItem2[0].name;
      }
      const categoryItem = this.category.filter((item) => item.id == parentId);
      return categoryItem[0].name;
    } else {
      if (parentId == "ROOT") {
        return "";
      }
      const categoryItem = this.category.filter(
        (item) => item.id == categoryId
      );
      return categoryItem[0].name;
    }
  }
  changeTemplate(data) {
    this.editData = data;
    this.isEdit = !this.isEdit;
  }
  searchData() {
    return __async(this, null, function* () {
      let rsql = emit(
        builder.and(builder.comparison("name", "=like=", this.searchdata))
      );
      this.gridController.query.filter = rsql;
      yield this.gridController.search();
    });
  }
  delTemplate(id) {
    return __async(this, null, function* () {
      try {
        yield API.delete(`api://base.admin/admin/v1/templates/${id}`);
        yield this.gridController.search();
      } catch (error) {
        throwErrorApi.throwError(error);
      }
    });
  }
  edit() {
    this.editData = {};
    this.isEdit = !this.isEdit;
  }
};
TemplateManagement = __decorateClass([
  Component({
    components: {
      UiGrid,
      Edittemplate
    }
  })
], TemplateManagement);
export {
  TemplateManagement as default
};
