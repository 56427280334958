"use strict";
import { Options as APIOptions } from "@ym-framework/api-utils";
import PluginMock from "@ym-framework/api-utils-mock";
import PluginModelResponse from "@ym-framework/api-utils-model-response";
import { mocks } from "@/mock";
import { Message } from "element-ui";
import LoginDialog from "@/views/home/views/dialog/LoginDialog.vue";
import router from "@/router";
import store from "@/store";
import Vue from "vue";
let baseUrl = "https://gateway.kuajingvs.com/ai/prompt";
if (process.env.YM_LOCAL_TEST === "true") {
  baseUrl = "https://gateway.kuajingvs.com/ai/prompt";
}
if (process.env.YM_LOCAL_MOCK) {
  const _mocks = mocks;
  APIOptions.plugins.install(new PluginMock((mock) => {
    for (const _mock of _mocks) {
      _mock(mock);
    }
  }));
}
class PlugErrorResponse {
  requestHandler(request) {
    return request;
  }
  responseRejected(error) {
    var _a, _b, _c, _d;
    if (((_d = (_c = (_b = (_a = error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.errors) == null ? void 0 : _c.datas[0]) == null ? void 0 : _d.code) === "auth.error.code.token.expired") {
      if (error.response.config.url == "/v1/chat-session" || error.response.config.url == "/v2/message-credits" || error.response.config.url == "/v1/payment-keys" || error.response.config.url == "/v1/draw/stream" || error.response.config.url == "v1/draw/status") {
        document.cookie = "chatmofa_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        localStorage.removeItem("token");
        return error;
      }
      if (router.currentRoute.fullPath.indexOf("admin") != -1) {
        localStorage.removeItem("admintoken");
        router.replace("/admin/adminlogin");
      } else {
        store.commit("accountInfo/updateSession", {
          model: null,
          account: "",
          accountId: "",
          orgId: "",
          userId: "",
          powers: [],
          extension: null,
          ip: null,
          device: null,
          area: null,
          labels: [],
          formal: true,
          ghost: false,
          token: ""
        });
        localStorage.removeItem("user");
        const _token = localStorage.getItem("token");
        if (_token) {
          document.cookie = "chatmofa_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
          localStorage.removeItem("token");
          return error;
        }
        const LoadingConstructor = Vue.extend(LoginDialog);
        const instance = new LoadingConstructor();
        const vm = instance.$mount();
        const layout = document.getElementsByClassName("layout");
        layout[0].appendChild(vm.$el);
        vm.open();
        Message.error("\u8BF7\u5148\u767B\u5F55");
        document.cookie = "chatmofa_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        localStorage.removeItem("token");
      }
    }
    return error;
  }
}
class PlugSsessionid {
  requestHandler(request) {
    return request;
  }
}
APIOptions.plugins.install(new PluginModelResponse());
APIOptions.plugins.install(new PlugErrorResponse());
APIOptions.plugins.install(new PlugSsessionid());
APIOptions.host.set("base", () => {
  return {
    baseURL: baseUrl
  };
});
APIOptions.host.set("base.auth", () => {
  return {
    baseURL: baseUrl,
    headers: {
      Authorization: localStorage.getItem("token") || ""
    }
  };
});
APIOptions.host.set("base.admin", () => {
  return {
    baseURL: baseUrl,
    headers: {
      Authorization: localStorage.getItem("admintoken") || ""
    }
  };
});
