"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import { accountInfoAPI } from "@/shared/api/account-info/account-info.api";
import store from "@/store";
let KuaJingOauth = class extends Vue {
  mounted() {
    return __async(this, null, function* () {
      try {
        const kjurl = chrome.clientServer().url;
        let kjtoken = (yield API.get(`${kjurl}/storages/preference/token`)).data.value.token;
        console.log(kjtoken);
        let token = (yield API.get(`api://base/v1/kuajingvs/authorized`, {
          headers: {
            "X-AUTH-TOKEN": kjtoken
          }
        })).data.token;
        if (token) {
          localStorage.setItem("token", token);
          const userinfo = yield accountInfoAPI.getAccountInfo();
          const credits = yield accountInfoAPI.getCredits();
          store.commit("accountInfo/updateSession", userinfo);
          store.commit("accountInfo/updateCredits", credits);
          this.$router.push("/");
        }
      } catch (err) {
      }
    });
  }
};
KuaJingOauth = __decorateClass([
  Component
], KuaJingOauth);
export {
  KuaJingOauth as default
};
