"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref } from "vue-property-decorator";
import UiForm, { UiFormController } from "@/components/form/UiForm.vue";
import UiSubmit from "@/components/form/UiSubmit.vue";
import Layout from "@/components/Layout.vue";
import API from "@ym-framework/api-utils";
import { homeApi } from "../../services/home.api";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { accountInfoAPI } from "@/shared/api/account-info/account-info.api";
import copyText from "@/utils/copyText";
import VueMarkdown from "vue-markdown";
export class FormController extends UiFormController {
}
let Content = class extends Vue {
  constructor() {
    super(...arguments);
    this.controller = new FormController();
    this.selectedLanguage = "\u4E2D\u6587";
    this.templateDetails = [];
    this.isChat = false;
    //加载中
    this.isloading = false;
    //是否可以发生消息请求
    this.isPost = true;
    //用户输入的信息
    this.msg = "";
    //聊天记录
    this.chatRecord = [];
    //最后一条消息记录id
    this.message_id = "";
    //初始问题
    this.question = "";
    this.chatboxscrollTop = 0;
    //输入类型
    this.inputType = "";
    //表单
    // formData: { [x: string]: any } = {};
    // eventSource: any;
    this.isStopGenerating = false;
    this.moremsgloading = false;
    this.isAllmsg = false;
    // 是否显示积分不足弹窗
    this.isConfirmBox = false;
  }
  vHtml(vHtml) {
    throw new Error("Method not implemented.");
  }
  mounted() {
    return __async(this, null, function* () {
      this.Details(this.$route.query.id);
      this.$store.commit("templateInfo/updateTemplateOptions", {
        language: "English"
      });
      let res = yield homeApi.getRecords();
      if (res.datas.length !== 0) {
        this.message_id = res.datas[res.datas.length - 1].id;
      }
      if (res.datas.length < 10) {
        this.isAllmsg = true;
      }
      res.datas.forEach((i) => {
        if (i.role == "USER") {
          this.chatRecord.unshift({
            content: i.content,
            user: "my",
            time: i.createdAt
          });
        } else {
          this.chatRecord.unshift({
            content: i.content,
            user: "robot",
            time: i.createdAt
          });
        }
      });
      this.updatemsg();
    });
  }
  // 停止生成
  stopGeneration() {
    this.isStopGenerating = true;
  }
  // 开始生成
  startGeneration() {
    this._Form._form.validate((isvalidate) => {
      if (!this.$store.state.accountInfo.session.account) {
        this.$message.error("\u8BF7\u5148\u767B\u5F55");
        this._layout.login();
      } else {
        if (isvalidate) {
          this.inputType = "splicing";
          this.isChat = true;
          let sendmsg = "";
          this.templateDetails.forEach((item, index) => {
            sendmsg += item.label + "\uFF1A" + item.options.defaultValue + "\n";
          });
          if (!this.isPost) {
            return;
          }
          this.isPost = false;
          this.isloading = true;
          this.chatRecord.push({
            content: sendmsg,
            user: "my",
            time: new Date().getTime()
          });
          this.updatemsg();
          this.sseMsg(sendmsg);
          this.updatemsg();
        }
      }
    });
  }
  Details(id) {
    return __async(this, null, function* () {
      let res = (yield API.get(`api://base/homepage/v1/templates/${id}`)).data;
      this.templateDetails = res.formTemplates;
      this.$store.commit("templateInfo/updatePageinfo", {
        title: res.title,
        introduce: res.introduce
      });
      this.question = res.question;
    });
  }
  updatemsg() {
    this.$nextTick(() => {
      let div = document.getElementById("chat-box");
      div.scrollTop = div.scrollHeight;
    });
  }
  divscrolling() {
    return __async(this, null, function* () {
      let divchat = document.getElementById("chat-box");
      if (divchat.scrollTop <= 2) {
        if (this.moremsgloading || this.isAllmsg) {
          return;
        }
        this.moremsgloading = true;
        if (this.message_id) {
          let res = yield homeApi.getRecords(this.message_id);
          this.moremsgloading = false;
          if (res.datas.length < 10) {
            this.isAllmsg = true;
          }
          if (res.datas.length !== 0) {
            this.message_id = res.datas[res.datas.length - 1].id;
            res.datas.forEach((i) => {
              if (i.role == "USER") {
                this.chatRecord.unshift({
                  content: i.content,
                  user: "my",
                  time: i.createdAt
                });
              } else {
                this.chatRecord.unshift({
                  content: i.content,
                  user: "robot",
                  time: i.createdAt
                });
              }
            });
            this.$nextTick(() => {
              let x = document.querySelectorAll(
                `#chat-box>.chat-data:nth-child(${res.datas.length + 1})`
              );
              this.chatboxscrollTop = x[0].offsetTop - x[0].offsetHeight;
              divchat.scrollTop = this.chatboxscrollTop - 3;
            });
          }
        }
      }
    });
  }
  // 打开积分不足弹窗
  openConfirmBox() {
    if (this.isConfirmBox) {
      return;
    }
    this.isConfirmBox = true;
    this.$confirm(`\u60A8\u7684\u70B9\u6570\u4E0D\u8DB3\uFF0C\u8BF7\u5145\u503C\u540E\u53EF\u4EE5\u7EE7\u7EED\u4F7F\u7528`, "\u63D0\u793A", {
      confirmButtonText: "\u5145\u503C",
      cancelButtonText: "\u53D6\u6D88",
      type: "warning",
      roundButton: true,
      customClass: "messageClass"
    }).then(() => {
      this._layout.goGptPackage();
    }).catch(() => {
    }).finally(() => {
      this.isConfirmBox = false;
    });
  }
  //发送消息
  sendMsg() {
    return __async(this, null, function* () {
      let gptmodel = this.$store.state.templateInfo.templateOptions.gptmodel;
      let creditBalance = this.$store.state.accountInfo.credits.totalRemainingMessageCredit;
      if (gptmodel === "gpt-3.5-turbo" && creditBalance <= 0) {
        this.openConfirmBox();
        return;
      } else if (gptmodel === "gpt-4" && creditBalance < 10) {
        this.openConfirmBox();
        return;
      }
      if (!this.isPost || this.msg.length == 0) {
        return;
      }
      this.inputType = "direct";
      this.isPost = false;
      this.isloading = true;
      let sendmsg = this.msg;
      this.msg = "";
      this.chatRecord.push({
        content: sendmsg,
        user: "my",
        time: new Date().getTime()
      });
      this.updatemsg();
      this.sseMsg(sendmsg);
      this.updatemsg();
    });
  }
  //sse
  sseMsg(sendmsg) {
    let gptmodel = this.$store.state.templateInfo.templateOptions.gptmodel;
    let language = this.$store.state.templateInfo.templateOptions.language;
    let templateId = this.$route.query.id;
    let inputType = this.inputType;
    let formTemplateVariables = {};
    this.templateDetails.forEach((item, index) => {
      formTemplateVariables[item.key] = item.options.defaultValue;
    });
    let isStopGenerating = this.isStopGenerating;
    let opsendmsg = () => __async(this, null, function* () {
      this.isPost = true;
      const credits = yield accountInfoAPI.getCredits();
      this.$store.commit("accountInfo/updateCredits", credits);
      this.isStopGenerating = false;
    });
    let getssemsg = () => {
      this.chatRecord.push({
        content: "",
        user: "robot",
        time: new Date().getTime()
      });
      this.isloading = false;
    };
    let updatessemsg = (msg) => {
      this.chatRecord[this.chatRecord.length - 1].content += msg;
      isStopGenerating = this.isStopGenerating;
      this.updatemsg();
    };
    class RetriableError extends Error {
    }
    class FatalError extends Error {
    }
    const abortController = new AbortController();
    let eventSource = fetchEventSource(
      `https://gateway.kuajingvs.com/ai/prompt/v1/conversation`,
      {
        method: "POST",
        openWhenHidden: true,
        signal: abortController.signal,
        body: JSON.stringify({
          input: sendmsg,
          model: gptmodel,
          inputType,
          language,
          templateId,
          formTemplateVariables
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token")
        },
        onopen() {
          return __async(this, null, function* () {
            getssemsg();
          });
        },
        onmessage(msg) {
          let msgdata = JSON.parse(msg.data);
          if (msgdata.content !== "[DONE]") {
            updatessemsg(msgdata.content);
          }
          if (isStopGenerating) {
            opsendmsg();
            abortController.abort();
            eventSource.close();
          }
        },
        onclose() {
          opsendmsg();
        },
        onerror(err) {
          console.log("error", err);
          abortController.abort();
          eventSource.close();
        }
      }
    );
  }
  //回车发生消息
  suball(event) {
    event = event ? event : window.event ? window.event : "";
    var keyCode = event.keyCode ? event.keyCode : event.which ? event.which : event.charCode;
    if (event.keyCode == 13 && event.ctrlKey) {
      this.msg = this.msg + "\n";
    } else if (keyCode == 13) {
      event.preventDefault();
      this.sendMsg();
    }
  }
  submit() {
    this.isloading = true;
  }
  // 复制文本
  copyText(type) {
    copyText.copyText(type);
  }
  goBack() {
    this.$router.push("/DocumentTemplate");
  }
  cleardata() {
    this.templateDetails.forEach((item, index) => {
      this.templateDetails[index].options.defaultValue = "";
    });
  }
};
__decorateClass([
  Ref("layout")
], Content.prototype, "_layout", 2);
__decorateClass([
  Ref("Form")
], Content.prototype, "_Form", 2);
Content = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit,
      Layout,
      VueMarkdown
    }
  })
], Content);
export {
  Content as default
};
