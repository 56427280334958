"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Watch } from "vue-property-decorator";
let AdminLayout = class extends Vue {
  constructor() {
    super(...arguments);
    this.menuactive = "";
    this.routerPath = this.$route.path;
    this.adminmenuItems = [
      {
        title: "\u6A21\u677F\u7BA1\u7406",
        index: "1",
        to: "/admin/template-management",
        icon: "el-icon-document"
      },
      {
        title: "\u5206\u7C7B\u7BA1\u7406",
        index: "2",
        to: "/admin/sort-management",
        icon: "el-icon-s-unfold"
      }
    ];
  }
  routerPathChanged(val, oldVal) {
    return __async(this, null, function* () {
      this.routerPath = this.$route.path;
    });
  }
  //退出
  logOut() {
    localStorage.removeItem("admintoken");
    this.$router.push("/admin/adminlogin");
  }
};
__decorateClass([
  Watch("$route.path", { deep: true })
], AdminLayout.prototype, "routerPathChanged", 1);
AdminLayout = __decorateClass([
  Component
], AdminLayout);
export {
  AdminLayout as default
};
