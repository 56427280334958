"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
class GptsessionApi {
  // 获取历史聊天记录
  getRecords(message_id, session_id) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chat-session-history`,
        {
          params: {
            message_id,
            session_id
          }
        }
      );
      return response.data;
    });
  }
  // 获取会话列表
  getSessionList(session_id, size) {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/chat-session`,
        {
          params: {
            session_id,
            size
          }
        }
      );
      return response.data.datas;
    });
  }
}
export const gptsessionApi = new GptsessionApi();
