"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import UiSubmit from "@/components/form/UiSubmit.vue";
import InputPassword from "@/shared/components/input-password/InputPassword.vue";
import ResetpasswordDialog from "@/views/auth/views/dialog/ResetpasswordDialog.vue";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import API from "@ym-framework/api-utils";
import { accountInfoAPI } from "@/shared/api/account-info/account-info.api";
import UiForm, {
  UiFormController,
  UiFormStatus
} from "@/components/form/UiForm.vue";
import store from "@/store";
import router from "@/router";
import Layout from "@/components/Layout.vue";
import { Base64 } from "js-base64";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.account = "";
    this.password = "";
  }
}
let PasswordLogin = class extends Vue {
  constructor() {
    super(...arguments);
    // 登录表单controller
    this.controller = new FormController();
    this.dialogVisible = false;
    this.checked = false;
    this.lastRequestSuccessTime = 0;
    this.rememberPw = localStorage.getItem("_remember_pw") === "true";
    // 手机号校验规则
    this.registerAccountRules = [
      {
        required: true,
        message: "\u8BF7\u8F93\u5165\u624B\u673A\u53F7",
        trigger: "blur"
      },
      {
        pattern: /^1[3456789]\d{9}$/,
        message: "\u624B\u673A\u53F7\u683C\u5F0F\u9519\u8BEF\uFF01",
        trigger: "blur"
      }
    ];
    // 密码校验规则
    this.registerPasswordRules = [
      {
        required: true,
        message: "\u8BF7\u8F93\u5165\u5BC6\u7801",
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[A-Za-z])(?=.*\d)[^\u4e00-\u9fa5 ]+$/,
        message: "\u8BF7\u8F93\u5165\u6B63\u786E\u5BC6\u7801\u683C\u5F0F\uFF0C\u5305\u62EC\u6570\u5B57\u548C\u5B57\u6BCD",
        trigger: "blur"
      },
      {
        min: 6,
        max: 24,
        message: "\u5BC6\u7801\u957F\u5EA6\u4F4D6-24\u4F4D",
        trigger: "blur"
      }
    ];
  }
  mounted() {
    const data = this.checkRemember();
    this.receiveRememberPw(data);
  }
  // 校验手机号
  validatePhone() {
    this._form._form.validateField("account", (errMsg) => {
      if (errMsg) {
        throw Error();
      }
    });
  }
  // 提交表单
  submit() {
    return __async(this, null, function* () {
      var _a;
      try {
        this.controller.status = UiFormStatus.LOADING;
        const loginResponse = (yield API.post(`api://base/v1/login`, this.controller)).data;
        localStorage.setItem("token", (_a = loginResponse.token) != null ? _a : "");
        this.userinfo = yield accountInfoAPI.getAccountInfo();
        this.credits = yield accountInfoAPI.getCredits();
        store.commit("accountInfo/updateSession", this.userinfo);
        store.commit("accountInfo/updateCredits", this.credits);
        router.go(0);
        if (this.rememberPw) {
          localStorage.setItem("_remember_pw", "true");
          this.setCookie("_a_e", this.controller.account);
          this.setCookie("_a_pw", this.controller.password);
        } else {
          this.setCookie("_a_e", "");
          this.setCookie("_a_pw", "");
        }
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.controller.status = UiFormStatus.COMPLETE;
        this.controller = new FormController();
      }
    });
  }
  // 重置密码
  Resetpassword() {
    this.$emit("Resetpassword");
  }
  /**
     * 记住密码时，将加密过的账密存储在cookie中
  
     * @param key
     * @param value
  
     */
  setCookie(key, value) {
    document.cookie = `${key}=${decodeURIComponent(
      Base64.encode(value)
    )};max-age=86400`;
  }
  /**
  
     * 获取加密过的账密
  
     * @param key
  
     */
  getCookie(key) {
    if (document.cookie.length > 0) {
      let start = document.cookie.indexOf(key + "=");
      if (start !== -1) {
        start = start + key.length + 1;
        let end = document.cookie.indexOf(";", start);
        if (end === -1) {
          end = document.cookie.length;
        }
        return unescape(document.cookie.substring(start, end));
      }
    }
    return "";
  }
  /**
  
     * 判断是否记住密码，是则自动带入
  
     */
  checkRemember() {
    if (localStorage.getItem("_remember_pw") === "true") {
      return {
        account: Base64.decode(this.getCookie("_a_e")),
        password: Base64.decode(this.getCookie("_a_pw"))
      };
    }
    return false;
  }
  // 拿到保存的账号密码填入表单
  receiveRememberPw(data) {
    if (data !== false) {
      this.controller.account = data.account;
      this.controller.password = data.password;
    }
  }
  /**
  
     * 修改是否记住密码
  
     * @param remember
  
     */
  changeRememberPw(remember) {
    if (!remember) {
      localStorage.removeItem("_remember_pw");
    }
  }
};
__decorateClass([
  Ref("loginForm")
], PasswordLogin.prototype, "_form", 2);
__decorateClass([
  Ref("resetpassworddialog")
], PasswordLogin.prototype, "_resetpassworddialog", 2);
__decorateClass([
  Ref("layout")
], PasswordLogin.prototype, "_layout", 2);
PasswordLogin = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit,
      InputPassword,
      ResetpasswordDialog,
      Layout
    }
  })
], PasswordLogin);
export {
  PasswordLogin as default
};
