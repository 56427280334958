"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { verifyCodeApi } from "@/shared/api/verify-code/verify-code.api";
export class InputVerifyCodeService {
  constructor() {
    /// 验证码倒计时
    this.codeTime = 0;
    this.codeLock = false;
  }
  // withDirectives:boolean = true
  /**
   * 发送验证码
   * @param vo
   */
  sendCode(vo, key) {
    return __async(this, null, function* () {
      if (this.codeLock) {
        return;
      }
      yield verifyCodeApi.sendCode(vo, key);
      this.codeLock = true;
      this.codeTime = 60;
      const _codeTime = setInterval(() => {
        if (this.codeTime) {
          this.codeTime--;
        } else {
          this.codeLock = false;
          clearInterval(_codeTime);
        }
      }, 1e3);
    });
  }
}
