"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import UiSubmit from "@/components/form/UiSubmit.vue";
import InputVerifyCode from "@/shared/components/input-verify-code/InputVerifyCode.vue";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import API from "@ym-framework/api-utils";
import InputPassword from "@/shared/components/input-password/InputPassword.vue";
import { ResetService } from "@/views/home/services/register.service";
import UiForm, {
  UiFormController,
  UiFormStatus
} from "@/components/form/UiForm.vue";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.verifyCode = "";
    this.password = "";
    // confirmPassword = "";
    this.account = "";
  }
}
let ResetpasswordDialog = class extends Vue {
  constructor() {
    super(...arguments);
    // 登录表单controller
    this.controller = new FormController();
    this.reseterService = new ResetService();
    this.code = "";
    // 条形码
    this.captcha = "";
    this.sessionkey = "";
    this.lastRequestSuccessTime = 0;
    this.dialogVisible = false;
    // 手机号校验规则
    this.phoneRules = [
      {
        required: true,
        message: "\u8BF7\u8F93\u5165\u624B\u673A\u53F7",
        trigger: "blur"
      },
      {
        pattern: /^1[3456789]\d{9}$/,
        message: "\u624B\u673A\u53F7\u683C\u5F0F\u9519\u8BEF\uFF01",
        trigger: "blur"
      }
    ];
    // 密码校验
    this.passwordRules = [
      {
        required: true,
        message: "\u8BF7\u8F93\u5165\u5BC6\u7801",
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[A-Za-z])(?=.*\d)[^\u4e00-\u9fa5 ]+$/,
        message: "\u5BC6\u7801\u5FC5\u987B\u5305\u542B\u5B57\u6BCD\u548C\u6570\u5B57",
        trigger: "blur"
      },
      {
        min: 6,
        max: 24,
        message: "\u8BF7\u8F93\u51656-24\u4F4D\u5BC6\u7801",
        trigger: "blur"
      }
    ];
    //   确认密码二次校验
    this.confirmpasswordRules = [
      {
        required: true,
        message: "\u8BF7\u518D\u6B21\u8F93\u5165\u5BC6\u7801",
        trigger: "blur"
      },
      {
        validator: this.checkConfirmPassword,
        trigger: "blur"
      },
      {
        pattern: /^(?=.*[A-Za-z])(?=.*\d)[^\u4e00-\u9fa5 ]+$/,
        message: "\u5BC6\u7801\u5FC5\u987B\u5305\u542B\u5B57\u6BCD\u548C\u6570\u5B57",
        trigger: "blur"
      },
      {
        min: 6,
        max: 24,
        message: "\u8BF7\u8F93\u51656-24\u4F4D\u5BC6\u7801",
        trigger: "blur"
      }
    ];
  }
  // 校验手机号
  validatePhone() {
    this._form._form.validateField("account", (errMsg) => {
      if (errMsg) {
        throw Error();
      }
    });
  }
  mounted() {
    this.getCaptcha();
  }
  //   密码一致性
  checkConfirmPassword(rule, value, callback) {
    return this.reseterService.checkConfirmPassword(
      value,
      this.controller.password,
      callback
    );
  }
  getCaptcha() {
    return __async(this, null, function* () {
      const currentTime = new Date().getTime();
      const timeSinceLastSuccess = currentTime - this.lastRequestSuccessTime;
      if (timeSinceLastSuccess < 1e3) {
        return;
      }
      this;
      const response = yield API.get(`api://base/v1/captcha`, {
        responseType: "blob"
      });
      this.sessionkey = response.headers["session-key"];
      this.lastRequestSuccessTime = currentTime;
      var blob = new Blob([response.data], { type: "image/png" });
      this.captcha = URL.createObjectURL(blob);
    });
  }
  submit() {
    return __async(this, null, function* () {
      try {
        this.controller.status = UiFormStatus.LOADING;
        const loginResponse = (yield API.post(`api://base.auth/v1/retrieve-password`, this.controller)).data;
        localStorage.removeItem("token");
        this.$store.commit("accountInfo/updateSession", {});
        localStorage.removeItem("user");
        this.$router.push("/");
        this.$message.success("\u4FEE\u6539\u5BC6\u7801\u6210\u529F\uFF0C\u5C06\u9000\u51FA\u767B\u5F55");
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.controller.status = UiFormStatus.COMPLETE;
        this.controller = new FormController();
        this.dialogVisible = false;
      }
    });
  }
  login() {
    this.$emit("returnLogin");
  }
};
__decorateClass([
  Ref("resetform")
], ResetpasswordDialog.prototype, "_form", 2);
ResetpasswordDialog = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit,
      InputVerifyCode,
      InputPassword
    }
  })
], ResetpasswordDialog);
export {
  ResetpasswordDialog as default
};
