"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import Painting from "../Painting.vue";
import API from "@ym-framework/api-utils";
import { LazyImg, Waterfall } from "vue-waterfall-plugin";
import copyText from "@/utils/copyText";
import "vue-waterfall-plugin/dist/style.css";
let Gallery = class extends Vue {
  constructor() {
    super(...arguments);
    this.listData = [];
    this.prompt = "";
    this.dialogVisible = false;
    this.isSearching = false;
    this.searchTerm = "";
    this.originalData = [];
    this.currentDialogItem = [];
    this.options = {
      perPage: 4,
      perMove: 1,
      gap: 10,
      gutter: 10
    };
    this.breakpoints = {
      1920: {
        rowPerView: 5
      },
      1200: {
        rowPerView: 5
      },
      1e3: {
        rowPerView: 4
      },
      800: {
        rowPerView: 3
      },
      500: {
        rowPerView: 2
      }
    };
    this.list = [];
  }
  mounted() {
    return __async(this, null, function* () {
      yield this.getList();
    });
  }
  getList() {
    return __async(this, null, function* () {
      const response = yield API.get(`api://base.auth/v1/draw`, {});
      this.listData = response.data.result.datas;
      this.listData = this.listData.filter(
        (item) => item.status === "COMPLETED"
      );
      this.listData.forEach((item) => {
        this.currentDialogItem = item;
      });
      this.list = this.listData.map((item) => ({
        prompt: item.prompt,
        src: item.girdImageUrl,
        commands: item.commands
      }));
    });
  }
  imgDialog(item) {
    this.currentDialogItem = item;
    this.dialogVisible = true;
    switch (this.currentDialogItem.commands.QUALITY) {
      case ".25":
        this.currentDialogItem.commands.QUALITY = "\u4F4E\u6E05";
        return;
      case ".5":
        this.currentDialogItem.commands.QUALITY = "\u6807\u51C6";
        return;
      case "1":
        this.currentDialogItem.commands.QUALITY = "\u9AD8\u6E05";
        return;
    }
  }
  copy(type) {
    copyText.copyText(type);
  }
  // 搜索
  searchgpt(searchTerm) {
    if (!this.prompt.trim()) {
      this.originalData = this.listData;
    } else {
      this.searchTerm = searchTerm;
      this.isSearching = true;
      this.originalData = this.listData.filter((item) => {
        return item.prompt.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }
  }
};
Gallery = __decorateClass([
  Component({
    components: {
      Painting,
      Waterfall,
      LazyImg
    }
  })
], Gallery);
export {
  Gallery as default
};
