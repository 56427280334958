"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import RegisterDialog from "@/views/auth/views/dialog/RegisterDialog.vue";
import ResetpasswordDialog from "@/views/auth/views/dialog/ResetpasswordDialog.vue";
import Login from "@/views/auth/views/dialog/Login.vue";
let LoginDialog = class extends Vue {
  constructor() {
    super(...arguments);
    this.activeName = "first";
    this.authType = "login";
    this.dialogVisible = false;
  }
  mounted() {
    if (this.$route.query.invite_code) {
      this.open();
      this.register();
    }
  }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.dialogVisible = true;
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
  }
  // 注册
  register() {
    this.authType = "register";
  }
  // 返回登录
  returnLogin() {
    this.authType = "login";
  }
  // 重置密码
  Resetpassword() {
    this.authType = "resetpassword";
  }
};
__decorateClass([
  Ref("registerdialog")
], LoginDialog.prototype, "_registerdialog", 2);
__decorateClass([
  Ref("login")
], LoginDialog.prototype, "_login", 2);
LoginDialog = __decorateClass([
  Component({
    components: {
      RegisterDialog,
      ResetpasswordDialog,
      Login
    }
  })
], LoginDialog);
export {
  LoginDialog as default
};
