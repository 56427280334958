"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import Painting from "../Painting.vue";
import API from "@ym-framework/api-utils";
import { paintingApi } from "@/views/aiPainting/services/painting.api";
import { accountInfoAPI } from "@/shared/api/account-info/account-info.api";
import SpellGenerationDialog from "./SpellGenerationDialog.vue";
let CreatePainting = class extends Vue {
  constructor() {
    super(...arguments);
    this.prompt = "";
    this.select = "midjourney";
    //翻译
    this.translation = "";
    // 是否正在加载
    this.isloading = false;
    // 图片地址
    this.girdImageUrl = "";
    this.imgData = [];
    // 图片地址
    this.singleImageUrls = [];
    this.drawIds = [];
    this.queryStatusArray = [];
    // 进度条
    this.progress = 0;
    // 否定提示
    this.isExpanded = false;
    //最后一条消息记录id
    this.draw_id = "";
    // 表单是否隐藏
    this.isFormVisible = true;
    this.chatboxscrollTop = 0;
    this.moremsgloading = false;
    this.isAllmsg = false;
    // 是否显示pc
    this.isShowPc = true;
    //获取屏幕宽度
    this.screenWidth = document.body.clientWidth;
    // 是否显示积分不足弹窗
    this.isConfirmBox = false;
    // 新手引导
    this.drawCompleted = false;
    this.commands = {
      // 图片比例
      AR: "1:1",
      // 混乱程度
      CHAOS: 0,
      // 风格化程度
      STYLIZE: 250,
      // 版本
      VERSION: "6.0",
      // 种子
      // SEED: null,
      // 画质
      QUALITY: "1",
      // 奇妙
      WEIRD: 0,
      // speed: "1",
      TESTP: false,
      TITLE: false,
      NO: ""
    };
    this.allowedRatios = ["1:1", "3:4", "4:3", "9:16", "16:9", "1:2", "3:2", "2:3"];
    this.custom = {
      customWidth: "",
      customHeight: ""
    };
    // 版本
    this.version = [
      {
        value: "6.0",
        label: "6.0"
      },
      {
        value: "5.2",
        label: "5.2"
      },
      {
        value: "5.1",
        label: "5.1"
      },
      {
        value: "5.0",
        label: "5.0"
      }
    ];
    // 速度
    this.speed = [
      {
        value: "1",
        label: "\u6B63\u5E38"
      },
      {
        value: "",
        label: "\u5FEB\u901F"
      }
    ];
    // 画质
    this.quality = [
      {
        value: ".25",
        label: "\u6E05\u6670"
      },
      {
        value: ".5",
        label: "\u6807\u51C6"
      },
      {
        value: "1",
        label: "\u9AD8\u6E05"
      }
      // {
      //   value: "",
      //   label: "超高清",
      // },
    ];
    this.buttons = [
      {
        type: "UPSCALE_SUBTLE",
        img: require("../../../../assets/images/icon-fangda.png"),
        label: "\u653E\u5927(\u5FAE\u5999)"
      },
      {
        type: "UPSCALE_CREATIVE",
        img: require("../../../../assets/images/icon-fangda.png"),
        label: "\u653E\u5927(\u521B\u610F)"
      },
      {
        type: "VARY_SUBTLE",
        img: require("../../../../assets/images/moshubang.png"),
        label: "\u8C03\u6574(\u5F31)"
      },
      {
        type: "VARY_STRONG",
        img: require("../../../../assets/images/moshubang.png"),
        label: "\u8C03\u6574(\u5F3A)"
      }
    ];
    this.zoombuttons = [
      {
        type: "ZOOM_OUT",
        img: require("../../../../assets/images/icon-waiyan.png"),
        label: "\u5916\u5EF62\u500D"
      },
      {
        type: "ZOOM_OUT",
        img: require("../../../../assets/images/icon-waiyan.png"),
        label: "\u5916\u5EF61.5\u500D"
      }
    ];
    this.panButtons = [
      {
        type: "PAN_LEFT",
        img: require("../../../../assets/images/icon-zuo.png"),
        label: "\u5411\u5DE6\u5916\u5EF6"
      },
      {
        type: "PAN_RIGHT",
        img: require("../../../../assets/images/icon-you.png"),
        label: "\u5411\u53F3\u5916\u5EF6"
      },
      {
        type: "PAN_UP",
        img: require("../../../../assets/images/icon-shang.png"),
        label: "\u5411\u4E0A\u5916\u5EF6"
      },
      {
        type: "PAN_DOWN",
        img: require("../../../../assets/images/icon-xia.png"),
        label: "\u5411\u4E0B\u5916\u5EF6"
      }
    ];
    this.myOptions = {
      useKeyboardNavigation: true,
      // 是否通过键盘控制指引
      enableScrolling: true,
      // 启用自动滚动
      highlight: true,
      // 高亮显示当前步骤的目标元素
      labels: {
        buttonSkip: "\u8DF3\u8FC7\u6307\u5F15",
        // 跳过文案
        buttonPrevious: "\u4E0A\u4E00\u6B65",
        // 上一步文案
        buttonNext: "\u4E0B\u4E00\u6B65",
        // 下一步文案
        buttonStop: "\u7ED3\u675F"
        // 结束文案
      }
    };
    this.steps = [
      {
        target: "#addSession",
        content: "\u8BF7\u9009\u62E9\u5BF9\u5E94\u7684\u7ED8\u753B\u53C2\u6570\uFF0C\u5F00\u59CB\u4F60\u7684\u7ED8\u753B\u4F53\u9A8C\u3002",
        params: {
          placement: "right",
          highlight: true
        }
      },
      {
        target: "#input",
        content: "\u8BF7\u5728\u8FD9\u91CC\u8F93\u5165\u7ED8\u753B\u5173\u952E\u8BCD",
        params: {
          placement: "left",
          highlight: true
        }
      },
      {
        target: "#send",
        content: "\u7136\u540E\u70B9\u51FB\u53D1\u9001\u6309\u94AE\uFF0C\u5F00\u59CB\u7ED8\u753B",
        params: {
          placement: "left",
          highlight: true
        }
      }
    ];
  }
  paintingSession(newVal, oldVal) {
    this.prompt = newVal.prompt;
    this.commands = newVal.commands;
    if (newVal.commands.NIJI == "NIJI") {
      this.select = "niji";
    } else {
      this.select = "midjourney";
    }
    if (!this.allowedRatios.includes(newVal.commands.AR)) {
      if (newVal.commands.AR && typeof newVal.commands.AR === "string") {
        let [width, height] = newVal.commands.AR.split(":").map(Number);
        if (!isNaN(width) && !isNaN(height)) {
          this.custom.customWidth = width;
          this.custom.customHeight = height;
        }
      }
    }
  }
  mounted() {
    return __async(this, null, function* () {
      const _token = localStorage.getItem("token");
      if (_token) {
        yield this.getList();
        this.onTimer(this.timerFn);
        const credits = yield accountInfoAPI.getCredits();
        this.$store.commit("accountInfo/updateCredits", credits);
      }
      this.saveToLocalStorage();
    });
  }
  saveToLocalStorage() {
    if (localStorage.hasOwnProperty("drawCompleted")) {
      return;
    }
    const guideData = {
      myOptions: this.myOptions,
      steps: this.steps
    };
    this.$tours["myTour"].start();
    localStorage.setItem("drawCompleted", "true");
  }
  //代入咒语
  importGeneration(info) {
    this.prompt = info;
  }
  // 监听屏幕变化
  handleResize() {
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth > 765) {
      this.isShowPc = true;
    } else {
      this.isShowPc = false;
    }
  }
  handleInput(type, value) {
    const maxValues = {
      WEIRD: 3e3,
      CHAOS: 100,
      STYLIZE: 1e3
    };
    const maxValue = maxValues[type];
    this.commands[type] = Number(value) > maxValue ? maxValue : Number(value);
  }
  // 否定提示
  toggleInput() {
    this.isExpanded = !this.isExpanded;
    this._negativeinput.focus();
    if (!this.isExpanded) {
      this.commands.NO = "";
      this._negativeinput.blur();
    }
  }
  toggleForm() {
    this.isFormVisible = !this.isFormVisible;
  }
  //回车发生消息
  suball(event) {
    event = event ? event : window.event ? window.event : "";
    var keyCode = event.keyCode ? event.keyCode : event.which ? event.which : event.charCode;
    if (event.keyCode == 13 && event.ctrlKey) {
      this.prompt = this.prompt + "\n";
    } else if (keyCode == 13) {
      event.preventDefault();
      this.getDraw();
    }
  }
  // 打开积分不足弹窗
  openConfirmBox() {
    if (this.isConfirmBox) {
      return;
    }
    this.isConfirmBox = true;
    this.$confirm(`\u60A8\u7684\u70B9\u6570\u4E0D\u8DB3\uFF0C\u8BF7\u5145\u503C\u540E\u53EF\u4EE5\u7EE7\u7EED\u4F7F\u7528`, "\u63D0\u793A", {
      confirmButtonText: "\u5145\u503C",
      cancelButtonText: "\u53D6\u6D88",
      type: "warning",
      roundButton: true,
      customClass: "messageClass"
    }).then(() => {
      this._painting.goGptPackage();
      this.isConfirmBox = false;
    });
  }
  getDraw() {
    return __async(this, null, function* () {
      if (!this.$store.state.accountInfo.session.account) {
        this.$message.error("\u8BF7\u5148\u767B\u5F55");
        this._painting.login();
      } else {
        if (this.prompt === "") {
          this.$message.error("\u8BF7\u8F93\u5165\u56FE\u50CF\u63CF\u8FF0\u6D88\u606F");
          return;
        }
        if (this.$store.state.accountInfo.credits.totalRemainingMessageCredit < 15) {
          this.openConfirmBox();
        }
        try {
          if (this.commands.AR === "custom" && this.custom.customWidth && this.custom.customHeight) {
            this.commands.AR = `${this.custom.customWidth}:${this.custom.customHeight}`;
          }
          if (this.select === "niji") {
            this.commands.NIJI = 5;
          } else {
            delete this.commands.NIJI;
          }
          this.commands.TESTP = this.commands.TESTP ? 5 : null;
          this.commands.TITLE = this.commands.TITLE ? 5 : null;
          const res = yield API.post(`api://base.auth/v1/draw`, {
            prompt: this.prompt,
            commands: this.commands,
            select: this.select
          });
          const credits = yield accountInfoAPI.getCredits();
          this.$store.commit("accountInfo/updateCredits", credits);
          yield this.getList();
          this.onTimer(this.timerFn);
          this.prompt = "";
          this.commands = {
            AR: "1:1",
            CHAOS: 0,
            STYLIZE: 250,
            VERSION: "",
            QUALITY: "1",
            WEIRD: 0,
            TESTP: false,
            TITLE: false
          };
        } catch (error) {
        } finally {
        }
      }
    });
  }
  // UV操作
  handleButtonClick(item, type, index) {
    return __async(this, null, function* () {
      if ((type === "REDRAW" || type === "VARIATE") && this.$store.state.accountInfo.credits.totalRemainingMessageCredit < 15) {
        this.openConfirmBox();
      } else {
        try {
          if (type === "REDRAW") {
            const response = yield API.post(
              `api://base.auth/v1/draw/${item.id}/inline-reply`,
              {
                type: "REDRAW"
              }
            );
          } else {
            const response = yield API.post(
              `api://base.auth/v1/draw/${item.id}/inline-reply`,
              {
                type,
                index
              }
            );
          }
          const credits = yield accountInfoAPI.getCredits();
          this.$store.commit("accountInfo/updateCredits", credits);
          yield this.getList();
          this.onTimer(this.timerFn);
        } catch (error) {
        }
      }
    });
  }
  // 放大调整外延操作
  imgOperate(button, item) {
    return __async(this, null, function* () {
      if (this.$store.state.accountInfo.credits.totalRemainingMessageCredit < 15) {
        this.openConfirmBox();
      } else {
        try {
          let value;
          switch (button.label) {
            case "\u5916\u5EF62\u500D":
              value = 50;
              break;
            case "\u5916\u5EF61.5\u500D":
              value = 75;
              break;
          }
          const response = yield API.post(
            `api://base.auth/v1/draw/${item.id}/inline-reply`,
            __spreadValues({
              type: button.type
            }, value !== void 0 && { value })
          );
          const credits = yield accountInfoAPI.getCredits();
          this.$store.commit("accountInfo/updateCredits", credits);
          yield this.getList();
          this.onTimer(this.timerFn);
        } catch (error) {
        }
      }
    });
  }
  getList() {
    return __async(this, null, function* () {
      const response = yield API.get(`api://base.auth/v1/draw/stream`, {
        params: {
          draw_id: "",
          size: 10
        }
      });
      response.data.datas.map((item) => {
        if (this.$store.state.paintingInfo.session.length) {
          this.$store.state.paintingInfo.session.forEach((listDataItem) => {
            if (listDataItem.id == item.id && (listDataItem == null ? void 0 : listDataItem.progress) > 0) {
              item.progress = listDataItem.progress;
            } else {
              item.progress = 0;
            }
          });
        } else {
          item.progress = 0;
        }
      });
      this.$store.state.paintingInfo.session = response.data.datas.reverse();
      this.draw_id = response.data.datas[0].id;
      this.updatemsg();
    });
  }
  // 监听滚动
  divscrolling() {
    return __async(this, null, function* () {
      try {
        let divchat = document.getElementById("img-box");
        if (divchat.scrollTop <= 2) {
          if (this.moremsgloading || this.isAllmsg || this.$store.state.paintingInfo.session.length < 9) {
            return;
          }
          this.moremsgloading = true;
          if (this.draw_id) {
            let res = yield paintingApi.getlist(this.draw_id, 10);
            this.moremsgloading = false;
            if (res.datas.length < 10) {
              this.isAllmsg = true;
            }
            if (res.datas.length !== 0) {
              this.draw_id = res.datas[res.datas.length - 1].id;
              res.datas.forEach((i) => {
                this.$store.state.paintingInfo.session.unshift(i);
              });
              this.$nextTick(() => {
                let x = document.querySelectorAll(
                  `#img-box>.img-data:nth-child(${res.datas.length + 1})`
                );
                this.chatboxscrollTop = x[0].offsetTop - x[0].offsetHeight;
                divchat.scrollTop = this.chatboxscrollTop + 467;
              });
            }
          }
        }
      } catch (error) {
      }
    });
  }
  updatemsg() {
    this.$nextTick(() => {
      let div = document.getElementById("img-box");
      div.scrollTop = div.scrollHeight;
    });
  }
  timerFn() {
    return __async(this, null, function* () {
      const response = yield API.post("api://base.auth/v1/draw/status", {
        drawIds: this.queryStatusArray
      });
      this.imgData = response.data.drawResponses;
      this.imgData.forEach((resItem) => {
        this.$store.state.paintingInfo.session.forEach(
          (item, index) => {
            if (item.id == resItem.drawId) {
              this.$store.state.paintingInfo.session[index].status = resItem.drawStatus;
              this.$store.state.paintingInfo.session[index].taskProgress = resItem.taskProgress;
              if (resItem.taskProgress) {
                this.$store.state.paintingInfo.session[index].progress = resItem.taskProgress.progress;
              }
            }
          }
        );
        if (resItem.drawStatus == "COMPLETED" || resItem.drawStatus == "FAILED") {
          this.queryStatusArray = this.queryStatusArray.filter(
            (item) => item != resItem.drawId
          );
          this.getList();
        }
      });
      if (!this.queryStatusArray.length) {
        clearInterval(this.QUERE);
      }
    });
  }
  onTimer(func) {
    this.$store.state.paintingInfo.session.forEach((item) => {
      if (item.status === "QUEUE" || item.status === "DRAWING") {
        this.queryStatusArray.push(item.id);
      }
    });
    if (!this.queryStatusArray.length) {
      return;
    }
    func();
    this.QUERE && clearInterval(this.QUERE);
    this.QUERE = setInterval(func, 1e3 * 10);
  }
  // 翻译
  translate() {
    return __async(this, null, function* () {
      if (this.prompt == "") {
        this.isloading = false;
        this.$message.error("\u8BF7\u5148\u8F93\u5165\u63CF\u8FF0\u8BCD\u5185\u5BB9");
      } else {
        this.isloading = true;
        const res = yield API.post(`api://base.auth/v1/draw/translation`, {
          input: this.prompt
        });
        this.prompt = res.data.output;
      }
      this.isloading = false;
      const credits = yield accountInfoAPI.getCredits();
      this.$store.commit("accountInfo/updateCredits", credits);
    });
  }
  //咒语生成
  openSpellGenerationDialog() {
    this._spellGenerationDialog.open();
  }
  // 删除绘画
  deleteImg(item, index) {
    return __async(this, null, function* () {
      this.$confirm(`\u662F\u5426\u5220\u9664\u5F53\u524D\u7ED8\u753B`, "\u5220\u9664", {
        confirmButtonText: "\u786E\u5B9A",
        cancelButtonText: "\u53D6\u6D88",
        type: "warning",
        roundButton: true,
        customClass: "messageClass"
      }).then(() => __async(this, null, function* () {
        yield API.delete(`api://base.auth/v1/draw/${item.id}`);
        this.$store.state.paintingInfo.session.splice(index, 1);
        this.$message.success("\u5220\u9664\u6210\u529F");
      }));
    });
  }
  // 重置参数
  resetting() {
    this.select = "midjourney";
    this.commands = {
      AR: "1:1",
      CHAOS: 0,
      STYLIZE: 250,
      // SEED: null,
      VERSION: "6.0",
      // 画质
      QUALITY: "1",
      // 奇妙
      WEIRD: 0,
      // speed: "",
      TESTP: false,
      TITLE: false
    };
  }
};
__decorateClass([
  Ref("painting")
], CreatePainting.prototype, "_painting", 2);
__decorateClass([
  Ref("negativeinput")
], CreatePainting.prototype, "_negativeinput", 2);
__decorateClass([
  Ref("spellGenerationDialog")
], CreatePainting.prototype, "_spellGenerationDialog", 2);
__decorateClass([
  Watch("$store.state.paintingInfo.draw", { deep: true, immediate: false })
], CreatePainting.prototype, "paintingSession", 1);
CreatePainting = __decorateClass([
  Component({
    components: {
      Painting,
      SpellGenerationDialog
    }
  })
], CreatePainting);
export {
  CreatePainting as default
};
