"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import UiForm, {
  UiFormController,
  UiFormStatus
} from "@/components/form/UiForm.vue";
import API from "@ym-framework/api-utils";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import UiSubmit from "@/components/form/UiSubmit.vue";
export class editFormdata {
  constructor() {
    this.categoryId = "";
    this.title = "";
    this.introduce = "";
    this.question = "";
    this.formTemplates = [
      {
        type: "INPUT",
        label: "",
        key: "",
        options: {
          type: "text",
          placeholder: "",
          maxlength: 20,
          defaultValue: ""
        },
        rule: {
          required: true,
          message: ""
        }
      }
    ];
  }
}
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.categoryId = "";
    this.id = "";
    this.title = "";
    this.introduce = "";
    this.question = "";
    this.formTemplates = [];
  }
}
let Edittemplate = class extends Vue {
  constructor() {
    super(...arguments);
    this.controller = new FormController();
    this.questionparam = "";
    this.firstCategory = "";
    this.secondCategory = [];
    this.Watchcount = 1;
  }
  onFirstCategory(val, oldVal) {
    if (this.Watchcount == 1) {
      this.Watchcount += 1;
      let second2 = this.category.filter((item) => item.id === val);
      if (second2.length) {
        this.secondCategory = second2[0].children;
      }
      return;
    }
    this.controller.categoryId = "";
    let second = this.category.filter((item) => item.id === val);
    if (second.length) {
      this.secondCategory = second[0].children;
    }
  }
  mounted() {
    for (let key in this.editData) {
      this.controller[key] = this.editData[key];
    }
    this.firstCategory = this.editData.parentId;
    if (this.editData.parentId == "ROOT") {
      this.controller.categoryId = "";
      this.firstCategory = this.editData.categoryId;
    }
  }
  addformTemplates() {
    this.controller.formTemplates.push({
      type: "INPUT",
      lable: "",
      key: "",
      options: {
        type: "text",
        placeholder: "",
        maxlength: 20,
        defaultValue: ""
      },
      rule: {
        required: true,
        message: ""
      }
    });
  }
  delformTemplates(index) {
    this.controller.formTemplates.splice(index, 1);
  }
  submit() {
    return __async(this, null, function* () {
      try {
        this.controller.formTemplates.forEach((item, index) => {
          this.controller.formTemplates[index].key = "param" + (index + 1);
        });
        this.controller.status = UiFormStatus.LOADING;
        if (this.editData.id == void 0) {
          yield API.post(`api://base.admin/admin/v1/templates`, this.controller);
        } else {
          yield API.put(
            `api://base.admin/admin/v1/templates/${this.controller.id}`,
            this.controller
          );
        }
        this.goBack();
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.controller.status = UiFormStatus.COMPLETE;
      }
    });
  }
  goBack() {
    this.$emit("edit");
  }
};
__decorateClass([
  Prop()
], Edittemplate.prototype, "editData", 2);
__decorateClass([
  Prop()
], Edittemplate.prototype, "category", 2);
__decorateClass([
  Watch("firstCategory", { immediate: false, deep: true })
], Edittemplate.prototype, "onFirstCategory", 1);
Edittemplate = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit
    }
  })
], Edittemplate);
export {
  Edittemplate as default
};
