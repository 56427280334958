"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue, Ref } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
let Home = class extends Vue {
  constructor() {
    super(...arguments);
    this.menuactive = "DocumentTemplate";
    this.data = [];
    this.searchdata = "";
    this.show = true;
    this.menuItems = [
      {
        title: "\u6587\u6863\u6A21\u677F",
        icon: "el-icon-tickets",
        to: "/document-template"
      },
      {
        title: "\u6211\u7684\u6536\u85CF",
        icon: "el-icon-star-off",
        to: "/my-collection"
      }
    ];
  }
  login() {
    this._layout.login();
  }
  active(menuItem) {
    return this.$route.path === menuItem.to;
  }
};
__decorateClass([
  Ref("layout")
], Home.prototype, "_layout", 2);
Home = __decorateClass([
  Component({
    components: {
      Layout
    }
  })
], Home);
export {
  Home as default
};
