"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import Layout from "@/components/Layout.vue";
import API from "@ym-framework/api-utils";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { accountInfoAPI } from "@/shared/api/account-info/account-info.api";
import { gptsessionApi } from "../../services/gptsession.api";
import { Toast } from "vant";
import "vant/lib/toast/style";
import "github-markdown-css/github-markdown.css";
import copyText from "@/utils/copyText";
import markdownBox from "./markdownBox.vue";
import { SpeechService } from "@/utils/speech-recognition.service";
Vue.use(Toast);
let Gptsession = class extends Vue {
  constructor() {
    super(...arguments);
    //加载中
    this.isloading = false;
    //是否可以发生消息请求
    this.isPost = true;
    //用户输入的信息
    this.msg = "";
    // 是否显示pc
    this.isShowPc = true;
    //聊天记录
    this.chatRecord = [];
    this.message_id = "";
    //当前会话id
    this.session_id = "";
    //获取屏幕宽度
    this.screenWidth = document.body.clientWidth;
    //会话列表
    this.sessionList = [];
    // 是否展开
    this.drawer = false;
    // gpt版本选择
    this.selectedVersion = "gpt-3.5-turbo";
    this.editSeeionTitle = "";
    this.isEditSeeion = false;
    this.isEditSeeionIndex = -1;
    this.chatboxscrollTop = 0;
    this.isShowGpt = false;
    this.moremsgloading = false;
    this.isAllmsg = false;
    // 是否显示积分不足弹窗
    this.isConfirmBox = false;
    // 获取语言列表
    this.languagedata = [];
    this.language = "Intelligent";
    // 是否展开会话
    this.isFormVisible = false;
    this.shortcutBoxes = [
      {
        id: 1,
        items: [{ id: 1, text: "\u5199\u9879\u76EE\u62A5\u544A\u6709\u54EA\u4E9B\u6280\u5DE7\uFF1F" }]
      },
      {
        id: 2,
        items: [{ id: 2, text: "\u5982\u4F55\u8DDF\u540C\u4E8B\u53CB\u597D\u76F8\u5904\uFF1F" }]
      },
      {
        id: 3,
        items: [{ id: 3, text: "300\u5B57\u4EA7\u54C1\u7ECF\u7406\u7684\u65E5\u62A5" }]
      },
      {
        id: 4,
        items: [{ id: 4, text: "\u600E\u4E48\u5199\u5C0F\u7EA2\u4E66\u7206\u6B3E\u6587\u6848\uFF1F" }]
      },
      {
        id: 5,
        items: [{ id: 5, text: "\u5E2E\u6211\u8D775\u4E2A\u7206\u6B3E\u6807\u9898" }]
      },
      {
        id: 6,
        items: [{ id: 6, text: "\u77ED\u89C6\u9891\u811A\u672C\u7F16\u5199\u6280\u5DE7" }]
      },
      {
        id: 7,
        items: [{ id: 7, text: "\u5E2E\u6211\u7FFB\u8BD1\u201C\u4EBA\u5DE5\u667A\u80FD\u201D" }]
      },
      {
        id: 8,
        items: [{ id: 8, text: "\u5982\u4F55\u5199\u4E00\u7BC7\u8BBA\u6587\u62A5\u544A\uFF1F" }]
      },
      {
        id: 9,
        items: [{ id: 9, text: "\u5E2E\u6211\u89E3\u91CA\u4E0B\u91CF\u5B50\u5B66\u539F\u7406" }]
      }
    ];
    // 语言
    this.languageoptions = [
      {
        value: "Intelligent",
        label: "\u667A\u80FD"
      },
      {
        value: "Chinese",
        label: "\u4E2D\u6587"
      },
      {
        value: "English",
        label: "\u82F1\u6587"
      },
      {
        value: "Spanish",
        label: "\u897F\u73ED\u7259\u8BED"
      },
      {
        value: "German",
        label: "\u5FB7\u8BED"
      },
      {
        value: "French",
        label: "\u6CD5\u8BED"
      },
      {
        value: "Italian",
        label: "\u610F\u5927\u5229\u8BED"
      },
      {
        value: "Japanese",
        label: "\u65E5\u8BED"
      },
      {
        value: "Dutch",
        label: "\u8377\u5170\u8BED"
      },
      {
        value: "Polish",
        label: "\u6CE2\u5170\u8BED"
      },
      {
        value: "Swedish",
        label: "\u745E\u5178\u8BED"
      },
      {
        value: "Korean",
        label: "\u97E9\u8BED"
      },
      {
        value: "Portuguese",
        label: "\u8461\u8404\u7259\u8BED"
      },
      {
        value: "Thai",
        label: "\u6CF0\u8BED"
      }
    ];
    this.last_session_id = "";
    this.isAllSession = false;
    this.inputVisible = false;
    this.inputValue = "";
    this.speechService = new SpeechService("zh-CN", true, true);
    // markdown = "# Hello, Vue Markdown!";
    // options = {
    //   html: true,
    //   typographer: true,
    //   linkify: true,
    //   breaks: true,
    //   highlight: function (code: any) {
    //     return require("highlight.js").highlightAuto(code).value;
    //   },
    // };
    this.loading = false;
  }
  instructionInfoChanged(val, oldVal) {
    this.addSession();
    if (val.length) {
      this.msg = val;
    }
    this.$store.commit("gptinstructionInfo/updateInstructionInfo", "");
  }
  toggleForm() {
    this.isFormVisible = !this.isFormVisible;
  }
  // @Watch("chatRecord", {
  //   deep: true,
  //   immediate: false,
  // })
  // chatRecordChanged(val: any, oldVal: any) {
  //   if (this.isPost) {
  //     return;
  //   }
  //   if (this.chatRecord[this.chatRecord.length - 1].user === "robot") {
  //     // this.chatRecord[this.chatRecord.length - 1].content = this.getMarkdown(
  //     //     this.chatRecord[this.chatRecord.length - 1].content
  //     //   );
  //     // this.$nextTick(() => {
  //     //   this.chatRecord[this.chatRecord.length - 1].content = this.getMarkdown(
  //     //     this.chatRecord[this.chatRecord.length - 1].content
  //     //   );
  //     //   this.$forceUpdate();
  //     // });
  //   }
  //   // console.log(this.chatRecord[this.chatRecord.length - 1].content);
  // }
  mounted() {
    return __async(this, null, function* () {
      this.$store.commit("templateInfo/updateTemplateOptions", {
        language: "Intelligent"
      });
      this.speechService.createRecognition();
      const _token = localStorage.getItem("token");
      if (_token) {
        this.sessionList = yield gptsessionApi.getSessionList(void 0, "20");
        if (this.sessionList.length == 20) {
          this.last_session_id = this.sessionList[this.sessionList.length - 1].id;
        } else {
          this.isAllSession = true;
        }
        const lang = yield API.get(`api://base.auth/v1/chat-language`);
        this.languagedata = lang.data.datas;
      }
      this.selectVersion("gpt-3.5-turbo");
      this.handleResize();
      window.addEventListener("resize", this.handleResize);
    });
  }
  speechResultChanged(val, oldVal) {
    this.msg = this.speechService.result;
  }
  //开始语音输入
  speechServiceStart() {
    if (this.speechService.isListening) {
      return;
    }
    this.speechService.start();
  }
  //停止语音输入
  speechServiceStop() {
    this.speechService.stop();
  }
  load() {
    return __async(this, null, function* () {
      if (this.loading || !this.last_session_id || this.isAllSession) {
        return;
      }
      this.loading = true;
      let moreSessionList = yield gptsessionApi.getSessionList(
        this.last_session_id,
        "20"
      );
      this.sessionList = this.sessionList.concat(moreSessionList);
      this.last_session_id = this.sessionList[this.sessionList.length - 1].id;
      if (moreSessionList.length < 20) {
        this.isAllSession = true;
      }
      this.loading = false;
    });
  }
  // noMore() {
  //   if (this.sessionList.length > 50) {
  //     return false;
  //   }
  // }
  // 关闭侧边栏
  handleClose() {
    this.drawer = false;
  }
  // gpt版本选择
  selectVersion(version) {
    this.selectedVersion = version;
    this.$store.commit("templateInfo/updateTemplateOptions", {
      gptmodel: version
    });
  }
  // 监听屏幕变化
  handleResize() {
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth > 765) {
      this.isShowPc = true;
    } else {
      this.isShowPc = false;
    }
  }
  // 复制
  copyToClipboard(type) {
    copyText.copyText(type);
  }
  clickShortcut(text) {
    this.msg = text;
    this.sendMsg();
  }
  //选择会话
  selectSession(item) {
    return __async(this, null, function* () {
      if (!this.isPost) {
        return;
      }
      this.msg = "";
      this.session_id = item.id;
      this.chatRecord = [];
      let res = yield gptsessionApi.getRecords(void 0, this.session_id);
      if (res.datas.length !== 0) {
        this.message_id = res.datas[res.datas.length - 1].id;
      }
      if (res.datas.length < 10) {
        this.isAllmsg = true;
      } else {
        this.isAllmsg = false;
      }
      res.datas.forEach((i) => {
        if (i.role == "USER") {
          this.chatRecord.unshift({
            content: i.content,
            user: "my",
            time: i.createdAt
          });
        } else {
          this.chatRecord.unshift({
            // content: this.getMarkdown(i.content),
            content: i.content,
            user: "robot",
            time: i.createdAt
          });
        }
      });
      this.drawer = false;
      this.isShowGpt = true;
      this.updatemsg();
    });
  }
  // 添加会话
  addSession() {
    if (!this.isPost) {
      return;
    }
    this.isShowGpt = false;
    this.chatRecord = [];
    this.session_id = "";
    this.drawer = false;
  }
  editSessionItem(item, index) {
    this.editSeeionTitle = item.sessionName;
    this.isEditSeeion = true;
    this.isEditSeeionIndex = index;
    this.$nextTick(() => {
      this._editSessionInput[0].focus();
    });
  }
  // 编辑会话
  endEdit(item, index) {
    return __async(this, null, function* () {
      try {
        this.isEditSeeion = false;
        this.isEditSeeionIndex = -1;
        yield API.put(`api://base.auth/v1/chat-session/${item.id}`, {
          orgId: item.orgId,
          sessionName: this.editSeeionTitle
        });
        this.sessionList[index].sessionName = this.editSeeionTitle;
      } catch (e) {
        this.$message.error("\u7F16\u8F91\u5931\u8D25");
      }
    });
  }
  // 删除单个会话
  delSessionItem(item, index) {
    return __async(this, null, function* () {
      this.$confirm(`\u662F\u5426\u5220\u9664\u4F1A\u8BDD`, "\u5220\u9664", {
        confirmButtonText: "\u786E\u5B9A",
        cancelButtonText: "\u53D6\u6D88",
        type: "warning",
        roundButton: true,
        customClass: "messageClass"
      }).then(() => __async(this, null, function* () {
        if (item.id == this.session_id) {
          this.isShowGpt = false;
          this.chatRecord = [];
          this.session_id = "";
        }
        yield API.delete(`api://base.auth/v1/chat-session/${item.id}`);
        this.sessionList.splice(index, 1);
      })).catch(() => {
      });
    });
  }
  // 清空会话
  delSession() {
    return __async(this, null, function* () {
      this.$confirm(`\u662F\u5426\u6E05\u7A7A\u4F1A\u8BDD`, "\u5220\u9664", {
        confirmButtonText: "\u786E\u5B9A",
        cancelButtonText: "\u53D6\u6D88",
        type: "warning",
        roundButton: true,
        customClass: "messageClass"
      }).then(() => __async(this, null, function* () {
        this.chatRecord = [];
        yield API.delete(`api://base.auth/v1/chat-dialogue/${this.session_id}`);
      })).catch(() => {
      });
    });
  }
  // 清空列表所有会话
  delAllSession() {
    return __async(this, null, function* () {
      this.$confirm(`\u662F\u5426\u6E05\u7A7A\u6240\u6709\u4F1A\u8BDD`, "\u5220\u9664", {
        confirmButtonText: "\u786E\u5B9A",
        cancelButtonText: "\u53D6\u6D88",
        type: "warning",
        roundButton: true,
        customClass: "messageClass"
      }).then(() => __async(this, null, function* () {
        this.isShowGpt = false;
        this.chatRecord = [];
        this.session_id = "";
        yield API.delete(`api://base.auth/v1/chat-sessions`);
        this.sessionList = yield gptsessionApi.getSessionList();
      })).catch(() => {
      });
    });
  }
  updatemsg() {
    this.$nextTick(() => {
      let div = document.getElementById("chat-box");
      div.scrollTop = div.scrollHeight;
    });
  }
  //监听滚动
  divscrolling() {
    return __async(this, null, function* () {
      let divchat = document.getElementById("chat-box");
      if (divchat.scrollTop <= 2) {
        if (this.moremsgloading || this.isAllmsg || this.chatRecord.length < 9) {
          return;
        }
        this.moremsgloading = true;
        if (this.message_id) {
          let res = yield gptsessionApi.getRecords(
            this.message_id,
            this.session_id
          );
          this.moremsgloading = false;
          if (res.datas.length < 10) {
            this.isAllmsg = true;
          }
          if (res.datas.length !== 0) {
            this.message_id = res.datas[res.datas.length - 1].id;
            res.datas.forEach((i) => {
              if (i.role == "USER") {
                this.chatRecord.unshift({
                  content: i.content,
                  user: "my",
                  time: i.createdAt
                });
              } else {
                this.chatRecord.unshift({
                  // content: this.getMarkdown(i.content),
                  content: i.content,
                  user: "robot",
                  time: i.createdAt
                });
              }
            });
            this.$nextTick(() => {
              let x = document.querySelectorAll(
                `#chat-box>.chat-data:nth-child(${res.datas.length + 1})`
              );
              this.chatboxscrollTop = x[0].offsetTop - x[0].offsetHeight;
              divchat.scrollTop = this.chatboxscrollTop - 3;
            });
          }
        }
      }
    });
  }
  // 打开积分不足弹窗
  openConfirmBox() {
    if (this.isConfirmBox) {
      return;
    }
    this.isConfirmBox = true;
    this.$confirm(`\u60A8\u7684\u70B9\u6570\u4E0D\u8DB3\uFF0C\u8BF7\u5145\u503C\u540E\u53EF\u4EE5\u7EE7\u7EED\u4F7F\u7528`, "\u63D0\u793A", {
      confirmButtonText: "\u5145\u503C",
      cancelButtonText: "\u53D6\u6D88",
      type: "warning",
      roundButton: true,
      customClass: "messageClass"
    }).then(() => {
      this._layout.goGptPackage();
    }).catch(() => {
    }).finally(() => {
      this.isConfirmBox = false;
    });
  }
  //发送消息
  sendMsg() {
    return __async(this, null, function* () {
      const _token = localStorage.getItem("token");
      if (!_token) {
        this.$message.error("\u8BF7\u5148\u767B\u5F55");
        this._layout.login();
        return;
      }
      if (!this.isPost || !this.msg || this.speechService.isListening) {
        return;
      }
      let creditBalance = this.$store.state.accountInfo.credits.totalRemainingMessageCredit;
      if (this.selectedVersion === "gpt-3.5-turbo" && creditBalance <= 0) {
        this.openConfirmBox();
        return;
      } else if (this.selectedVersion === "gpt-4" && creditBalance < 10) {
        this.openConfirmBox();
        return;
      }
      this.isShowGpt = true;
      this.isloading = true;
      let sendmsg = this.msg;
      this.msg = "";
      this.chatRecord.push({
        content: sendmsg,
        user: "my",
        time: new Date().getTime()
      });
      this.isPost = false;
      this.updatemsg();
      this.sseMsg(sendmsg);
      this.updatemsg();
    });
  }
  //sse
  sseMsg(sendmsg) {
    let sessionId = this.session_id;
    let gptmodel = this.$store.state.templateInfo.templateOptions.gptmodel;
    let language = this.language;
    let opsendmsg = () => __async(this, null, function* () {
      this.isPost = true;
      const credits = yield accountInfoAPI.getCredits();
      this.$store.commit("accountInfo/updateCredits", credits);
      if (this.chatRecord.length == 2) {
        this.sessionList = yield gptsessionApi.getSessionList(void 0, "20");
        this.session_id = this.sessionList[0].id;
      }
    });
    let getssemsg = () => {
      this.chatRecord.push({
        content: "",
        user: "robot",
        time: new Date().getTime()
      });
      this.isloading = false;
    };
    let updatessemsg = (msg) => {
      this.chatRecord[this.chatRecord.length - 1].content += msg;
      this.updatemsg();
    };
    class RetriableError extends Error {
    }
    class FatalError extends Error {
    }
    const abortController = new AbortController();
    let eventSource = fetchEventSource(
      `https://gateway.kuajingvs.com/ai/prompt/v1/send-chat-session`,
      {
        method: "POST",
        openWhenHidden: true,
        signal: abortController.signal,
        body: JSON.stringify({
          input: sendmsg,
          model: gptmodel,
          sessionId,
          language
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token")
        },
        onopen() {
          return __async(this, null, function* () {
            getssemsg();
          });
        },
        onmessage(msg) {
          let msgdata = JSON.parse(msg.data);
          if (msgdata.content !== "[DONE]") {
            updatessemsg(msgdata.content);
          }
        },
        onclose() {
          opsendmsg();
        },
        onerror(err) {
          console.log("error", err);
          abortController.abort();
          eventSource.close();
        }
      }
    );
  }
  //回车发生消息
  suball(event) {
    event = event ? event : window.event ? window.event : "";
    var keyCode = event.keyCode ? event.keyCode : event.which ? event.which : event.charCode;
    if (event.keyCode == 13 && event.ctrlKey) {
      this.msg = this.msg + "\n";
    } else if (keyCode == 13) {
      event.preventDefault();
      this.sendMsg();
    }
  }
};
__decorateClass([
  Ref("editSessionInput")
], Gptsession.prototype, "_editSessionInput", 2);
__decorateClass([
  Ref("layout")
], Gptsession.prototype, "_layout", 2);
__decorateClass([
  Watch("$store.state.gptinstructionInfo.instructionInfo", {
    deep: true,
    immediate: false
  })
], Gptsession.prototype, "instructionInfoChanged", 1);
__decorateClass([
  Watch("speechService.result", {
    deep: true,
    immediate: false
  })
], Gptsession.prototype, "speechResultChanged", 1);
Gptsession = __decorateClass([
  Component({
    components: {
      Layout,
      markdownBox
    }
  })
], Gptsession);
export {
  Gptsession as default
};
