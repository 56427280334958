"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, PropSync, Vue, Ref } from "vue-property-decorator";
export * from "./annotations";
export var UiFormStatus = /* @__PURE__ */ ((UiFormStatus2) => {
  UiFormStatus2["LOADING"] = "loading";
  UiFormStatus2["COMPLETE"] = "complete";
  return UiFormStatus2;
})(UiFormStatus || {});
export class UiFormController {
  constructor() {
    this._status = "complete" /* COMPLETE */;
  }
  properties() {
    return Object.getOwnPropertyNames(this);
  }
  loading() {
    return this._status === "loading" /* LOADING */;
  }
  set status(status) {
    this._status = status;
  }
  get status() {
    return this._status;
  }
  // public defaultValues(): any {
  //     const defaultValues: any = {};
  //     for (const _property of this.properties()) {
  //         const _has = Reflect.hasMetadata("form.default.value", this, _property);
  //         if (_has) {
  //             const _value = Reflect.getMetadata("form.default.value", this, _property);
  //             defaultValues[_property] = _value;
  //         }
  //     }
  //     return defaultValues;
  // }
  rules() {
    const rules = {};
    for (const _property of this.properties()) {
      const _has = Reflect.hasMetadata("form.rules", this, _property);
      if (_has) {
        const _value = Reflect.getMetadata("form.rules", this, _property);
        rules[_property] = _value;
      }
    }
    return rules;
  }
}
let UiForm = class extends Vue {
  submit() {
    return __async(this, null, function* () {
      try {
        let _validate = yield this._form.validate();
        this.$emit("submit", this._controller);
      } catch (error) {
        console.log(error);
      }
    });
  }
};
__decorateClass([
  Ref("_form-element")
], UiForm.prototype, "_form", 2);
__decorateClass([
  PropSync("controller", { type: UiFormController, default: null })
], UiForm.prototype, "_controller", 2);
UiForm = __decorateClass([
  Component
], UiForm);
export {
  UiForm as default
};
