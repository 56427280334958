"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import LoginDialog from "@/views/home/views/dialog/LoginDialog.vue";
import { CoreAccessRule } from "@/framework/access-control-lists";
import { Message } from "element-ui";
import Vue from "vue";
export class GuestAccess extends CoreAccessRule {
  next(to, from, next) {
    return __async(this, null, function* () {
      next();
    });
  }
}
export class AuthAccess extends CoreAccessRule {
  next(to, from, next) {
    return __async(this, null, function* () {
      const _token = localStorage.getItem("token");
      if (!_token) {
        const LoadingConstructor = Vue.extend(LoginDialog);
        const instance = new LoadingConstructor();
        const vm = instance.$mount();
        const layout = document.getElementsByClassName("layout");
        layout[0].appendChild(vm.$el);
        vm.open();
        Message.error("\u8BF7\u5148\u767B\u5F55");
        document.cookie = "chatmofa_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        return;
      }
      next();
    });
  }
}
export class AdminAccess extends CoreAccessRule {
  next(to, from, next) {
    return __async(this, null, function* () {
      const _token = localStorage.getItem("admintoken");
      if (!_token) {
        next("/admin/adminlogin");
      }
      next();
    });
  }
}
