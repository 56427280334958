"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import Vue from "vue";
import VueI18n from "vue-i18n";
import elementEnLocale from "element-ui/lib/locale/lang/en";
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";
const enLocale = require("./en.json");
const zhLocale = require("./zh.json");
Vue.use(VueI18n);
const messages = {
  en: __spreadValues(__spreadValues({}, enLocale), elementEnLocale),
  zh: __spreadValues(__spreadValues({}, zhLocale), elementZhLocale)
};
export const getLocale = () => {
  const cookieLanguage = localStorage.getItem("lang");
  if (cookieLanguage) {
    document.documentElement.lang = cookieLanguage;
    return cookieLanguage;
  }
  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      document.documentElement.lang = locale;
      let lang = "zh-CN";
      if (locale === "zh") {
        lang = "zh-CN";
      }
      if (locale === "en") {
        lang = "en-US";
      }
      localStorage.setItem("lang", lang);
      return locale;
    }
  }
  localStorage.setItem("lang", "zh-CN");
  return "zh-CN";
};
const i18n = new VueI18n({
  locale: getLocale(),
  // 语言标识
  fallbackLocale: "zh-CN",
  //默认语言
  silentFallbackWarn: true,
  //抑制警告
  messages
});
export default i18n;
