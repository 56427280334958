"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Ref, Vue } from "vue-property-decorator";
import UiSubmit from "@/components/form/UiSubmit.vue";
import InputVerifyCode from "@/shared/components/input-verify-code/InputVerifyCode.vue";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import API from "@ym-framework/api-utils";
import { accountInfoAPI } from "@/shared/api/account-info/account-info.api";
import UiForm, {
  UiFormController,
  UiFormStatus
} from "@/components/form/UiForm.vue";
import store from "@/store";
import router from "@/router";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.account = "";
    this.verifyCode = "";
  }
}
let PhoneLogin = class extends Vue {
  constructor() {
    super(...arguments);
    // 登录表单controller
    this.controller = new FormController();
    this.dialogVisible = false;
    // 条形码
    this.captcha = "";
    this.code = "";
    this.sessionkey = "";
    this.lastRequestSuccessTime = 0;
    // 手机号校验规则
    this.registerAccountRules = [
      {
        required: true,
        message: "\u8BF7\u8F93\u5165\u624B\u673A\u53F7",
        trigger: "blur"
      },
      {
        pattern: /^1[3456789]\d{9}$/,
        message: "\u624B\u673A\u53F7\u683C\u5F0F\u9519\u8BEF\uFF01",
        trigger: "blur"
      }
    ];
  }
  mounted() {
    this.getCaptcha();
  }
  // 校验手机号
  validatePhone() {
    this._form._form.validateField("account", (errMsg) => {
      if (errMsg) {
        throw Error();
      }
    });
  }
  // 条形码
  getCaptcha() {
    return __async(this, null, function* () {
      const currentTime = new Date().getTime();
      const timeSinceLastSuccess = currentTime - this.lastRequestSuccessTime;
      if (timeSinceLastSuccess < 1e3) {
        return;
      }
      this;
      const response = yield API.get(`api://base/v1/captcha`, {
        responseType: "blob"
      });
      this.sessionkey = response.headers["session-key"];
      this.lastRequestSuccessTime = currentTime;
      var blob = new Blob([response.data], { type: "image/png" });
      this.captcha = URL.createObjectURL(blob);
    });
  }
  submit() {
    return __async(this, null, function* () {
      var _a;
      try {
        this.controller.status = UiFormStatus.LOADING;
        const loginResponse = (yield API.post(`api://base/v1/login/verify-code`, this.controller)).data;
        localStorage.setItem("token", (_a = loginResponse.token) != null ? _a : "");
        this.userinfo = yield accountInfoAPI.getAccountInfo();
        this.credits = yield accountInfoAPI.getCredits();
        store.commit("accountInfo/updateSession", this.userinfo);
        store.commit("accountInfo/updateCredits", this.credits);
        router.go(0);
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.controller.status = UiFormStatus.COMPLETE;
        this.controller = new FormController();
        this.dialogVisible = false;
      }
    });
  }
};
__decorateClass([
  Ref("loginForm")
], PhoneLogin.prototype, "_form", 2);
PhoneLogin = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit,
      InputVerifyCode
    }
  })
], PhoneLogin);
export {
  PhoneLogin as default
};
