"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import UiSubmit from "@/components/form/UiSubmit.vue";
import UiForm, {
  UiFormController
} from "@/components/form/UiForm.vue";
import copyText from "@/utils/copyText";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.imageDescription = "";
    this.renderStyle = "Quixel Megascans Render";
    this.mediaType = "Illustration";
    this.sceneType = "Near future city";
    this.precisionType = "1080P";
    this.viewType = "Portrait";
    this.lightingType = "Front lighting";
    this.styleType1 = "Tradition Chinese Ink Painting";
    this.styleType2 = "Renaissance";
  }
  // ratio = "16:9";
}
let SpellGenerationDialog = class extends Vue {
  constructor() {
    super(...arguments);
    // 表单controller
    this.controller = new FormController();
    this.dialogVisible = false;
    this.midjourney = "";
    // ratioList = ["16:9", "4:3", "3:2", "1:1"];
    this.renderStyleList = [
      { label: "\u771F\u5B9E\u611F\u6E32\u67D3", value: "Quixel Megascans Render" },
      { label: "\u865A\u5E7B\u5F15\u64CE", value: "Unreal Engine" },
      { label: "OC\u6E32\u67D3", value: "Octane Render" },
      { label: "\u6E32\u67D3", value: "Maxon Cinema 4D" },
      { label: "\u5EFA\u7B51\u6E32\u67D3", value: "Architectural Visualisation" },
      { label: "\u5BA4\u5185\u6E32\u67D3", value: "Corona Render" },
      { label: "V\u5C04\u7EBF", value: "V-Ray" }
    ];
    this.mediaTypeList = [
      { label: "\u63D2\u753B", value: "Illustration" },
      { label: "\u6CB9\u753B", value: "Oil painting" },
      { label: "\u6444\u5F71", value: "Photography" },
      { label: "\u6C34\u5F69", value: "Watercolor" },
      { label: "\u7D20\u63CF", value: "Sketch" },
      { label: "\u96D5\u5851", value: "Ink painting" },
      { label: "\u6C34\u58A8\u753B", value: "Sculpture" },
      { label: "\u5F69\u94C5\u7D20\u63CF", value: "Color pencil sketch" },
      { label: "\u5370\u5237\u7248\u753B", value: "Blockprint" },
      { label: "\u5229\u8BFA\u526A\u88C1", value: "Lino cut" },
      { label: "\u624B\u7A3F", value: "Manuscript" },
      { label: "16\u4F4D\u56FE", value: "16 Bit" },
      { label: "8\u4F4D\u56FE", value: "8 Bit" },
      { label: "3D\u6253\u5370", value: "3D Printed" },
      { label: "\u4E19\u70EF\u753B", value: "Acrylic Painting" },
      { label: "\u76F8\u7EB8\u6253\u5370", value: "Albument Print" },
      { label: "\u7C89\u5F69\u77F3\u818F\u96D5\u5851", value: "Chalkware" },
      { label: "\u5411\u91CF\u56FE", value: "Vector" }
    ];
    this.sceneTypeList = [
      { label: "\u8FD1\u672A\u6765\u90FD\u5E02", value: "Near future city" },
      { label: "\u53CD\u4E4C\u6258\u90A6", value: "Dystopia, anti-utopia" },
      { label: "\u5E7B\u60F3", value: "Fantasy" },
      { label: "\u6559\u5BA4", value: "Classroom" },
      { label: "\u5F02\u60F3\u5929\u5F00", value: "Whimsically" },
      { label: "\u5367\u5BA4", value: "Bedroom" },
      { label: "\u68EE\u6797", value: "Forest" },
      { label: "\u5E9F\u589F", value: "Ruins" },
      { label: "\u57CE\u5E02", value: "City" },
      { label: "\u5E9F\u5F03\u57CE\u5E02\u5EFA\u7B51\u7FA4", value: "Deserted city buildings" },
      { label: "\u8857\u666F", value: "Street scenery" },
      { label: "\u70BC\u91D1\u5BA4", value: "Alchemy Laboratory" },
      { label: "\u5B87\u5B99", value: "Universe I cosmos" },
      { label: "\u96E8\u5929", value: "Rain" },
      { label: "\u5728\u6668\u96FE\u4E2D", value: "In the morning mist" },
      { label: "\u5145\u6EE1\u9633\u5149", value: "Full of sunlight" },
      { label: "\u94F6\u6CB3", value: "Galaxy" },
      { label: "\u9ED1\u6697\u5730\u7262", value: "Dungeon" },
      { label: "\u661F\u4E91", value: "Nebula" },
      { label: "\u75AF\u72C2\u9EA6\u65AF\u6C99\u5730\u98CE\u683C", value: "Mad Max" },
      { label: "\u5DF4\u6BD4\u4F26\u7A7A\u4E2D\u82B1\u56ED", value: "Hanging Gardens of Babylon" },
      { label: "\u8349\u539F\u8349\u5730", value: "Meadow" },
      { label: "\u6742\u8349\u4E1B\u751F\u7684", value: "Overgrown nature" },
      { label: "\u540E\u542F\u793A\u5F55\u3001\u672B\u65E5\u540E", value: "Post apocalyptic" },
      { label: "\u5929\u7A7A\u4E4B\u57CE", value: "Castle in the Sky" }
    ];
    this.precisionTypeList = [
      { label: "\u5168\u9AD8\u6E05 (1080P)", value: "1080P" },
      { label: "2K", value: "2K" },
      { label: "4K", value: "4K" },
      { label: "8K", value: "8K" },
      { label: "8K\u6D41\u7545", value: "8K smooth" },
      { label: "\u9AD8\u7EC6\u8282", value: "High detail" },
      { label: "\u9AD8\u54C1\u8D28", value: "Hyper quality" },
      { label: "\u9AD8\u5206\u8FA8\u7387", value: "High resolution" },
      { label: "\u5168\u9AD8\u6E05 (FHD)", value: "FHD" }
    ];
    this.viewTypeList = [
      { label: "\u8096\u50CF", value: "Portrait" },
      { label: "\u7279\u5199", value: "Close-Up (CU)" },
      { label: "\u4E2D\u7279\u5199", value: "Medium Close-Up (MCU)" },
      { label: "\u4E2D\u666F", value: "Medium Shot (MS)" },
      { label: "\u4E2D\u8FDC\u66B4", value: "Medium Long Shot (MLS)" },
      { label: "\u8FDC\u666F", value: "Long Shot (LS)" },
      { label: "\u8FC7\u80A9\u666F", value: "Over the shoulder shot" },
      { label: "\u677E\u6563\u666F", value: "Loose shot" },
      { label: "\u8FD1\u8DDD\u79BB\u666F", value: "Tight shot" },
      { label: "\u7535\u5F71\u955C\u5934", value: "Cinematic shot" },
      { label: "\u4E4C\u77B0\u56FE", value: "A bird\u2019s-eye view, aerial view" },
      { label: "\u9876\u89C6\u5712", value: "Top view" },
      { label: "\u503E\u659C\u79FB\u4F4D", value: "Tilt-shift" },
      { label: "\u536B\u661F\u89C6\u56FE", value: "Satellite view" },
      { label: "\u5E95\u89C6\u56FE", value: "Bottom view" },
      { label: "\u524D\u89C6\u56FE\u3001\u4FA7\u89C6\u56FE\u3001\u540E\u89C6\u56FE", value: "Front, side, rear view" },
      { label: "\u4EA7\u54C1\u89C6\u56FE", value: "Product view" },
      { label: "\u6781\u7AEF\u7279\u5199\u89C6\u56FE", value: "Extreme closeup view" },
      { label: "\u4EF0\u89C6", value: "Look up" },
      { label: "\u7B2C\u4E00\u4EBA\u79F0\u89C6\u89D2", value: "First-person view" },
      { label: "\u7B49\u8DDD\u89C6\u56FE", value: "Isometric view" },
      { label: "\u7279\u5199\u89C6\u56FE", value: "Closeup view" },
      { label: "\u9AD8\u89D2\u5EA6\u89C6\u56FE", value: "High angle view" },
      { label: "\u5FAE\u89C2", value: "Microscopic view" },
      { label: "\u8D85\u4FA7\u89D2", value: "Super side angle" },
      { label: "\u7B2C\u4E09\u4EBA\u79F0\u89C6\u89D2", value: "Third-person perspective" },
      { label: "\u9E1F\u77B0\u56FE", value: "Aerial view" },
      { label: "\u4E24\u70B9\u900F\u89C6", value: "Two-point perspective" },
      { label: "\u4E09\u70B9\u900F\u89C6", value: "Three-point perspective" },
      { label: "\u7ACB\u9762\u900F\u89C6", value: "Elevation perspective" },
      { label: "\u8D85\u5E7F\u89D2\u955C\u5934", value: "Ultra wide shot" },
      { label: "\u7206\u5934", value: "Headshot" },
      { label: "(\u6838\u6843)\u7684\u6A2A\u622A\u9762\u56FE", value: "A cross-section view of (a walnut)" },
      { label: "\u7126\u70B9\u5BF9\u51C6", value: "In focus" },
      { label: "\u666F\u6DF1(dof)", value: "Depth of field (DOF)" },
      {
        label: "\u5E7F\u89D2\u955C\u5934\u76F8\u673A\u578B\u53F7",
        value: "Canon 5D, 1Fujifilm XT100, Sony Alpha"
      },
      { label: "\u98CE\u666F\u7167", value: "Scenery shot" },
      { label: "\u80CC\u666F\u865A\u5316", value: "Bokeh" },
      { label: "\u524D\u666F", value: "Foreground" },
      { label: "\u80CC\u666F", value: "Background" },
      { label: "\u7EC6\u8282\u955C\u5934(ECU)", value: "Detail Shot (ECU)" },
      { label: "\u9762\u90E8\u62CD\u6444(VCU)", value: "Face Shot (VCU)" },
      { label: "\u819D\u666F(KS)", value: "Knee Shot (KS)" },
      { label: "\u5168\u8EAB\u7167(FLS)", value: "Full Length Shot (FLS)" },
      { label: "\u5927\u7279\u5199", value: "Big Close-Up (BCU)" },
      { label: "\u80F8\u90E8\u4EE5\u4E0A", value: "Chest Shot (MCU)" },
      { label: "\u8170\u90E8\u4EE5\u4E0A", value: "Waist Shot (WS)" },
      { label: "\u819D\u76D6\u4EE5\u4E0A", value: "Knee Shot (KS)" },
      { label: "\u5168\u8EAB", value: "Full Length Shot (FLS)" },
      { label: "\u4EBA\u53603/4", value: "Long Shot (LS)" },
      { label: "\u4EBA\u5728\u8FDC\u65B9", value: "Extra Long Shot (ELS)" },
      { label: "\u5934\u90E8\u4EE5\u4E0A", value: "Big Close-Up(BCU)" },
      { label: "\u8138\u90E8\u7279\u5199", value: "Face Shot (VCU)" }
    ];
    this.lightingTypeList = [
      { label: "\u524D\u706F", value: "Front lighting" },
      { label: "\u80CC\u5149\u7167\u660E", value: "Back lighting" },
      { label: "\u65E5\u51FA \u65E5\u843D", value: "Golden Hour" },
      { label: "\u661F\u7A7A", value: "Starry" },
      { label: "\u4F53\u79EF\u7167\u660E", value: "Volumetric lighting" },
      { label: "\u51B7\u5149", value: "Cold light" },
      { label: "\u60C5\u7EEA\u7167\u660E", value: "Mood lighting" },
      { label: "\u660E\u4EAE\u7684", value: "Bright" },
      { label: "\u67D4\u548C\u7684\u7167\u660E/\u67D4\u5149", value: "Soft illumination/soft lights" },
      { label: "\u8367\u5149\u706F", value: "Fluorescent lighting" },
      { label: "\u5FAE\u5149/\u6668\u5149", value: "Rays of shimmering light/morning light" },
      { label: "\u9EC4\u660F\u5C04\u7EBF", value: "Crepuscular Ray" },
      { label: "\u5916\u592A\u7A7A\u89C2", value: "Outer space view" },
      {
        label: "\u7535\u5F71\u706F\u5149/\u620F\u5267\u706F\u5149",
        value: "Cinematic lighting/dramatic lighting"
      },
      { label: "\u53CC\u6027\u7167\u660E", value: "Bisexual lighting" },
      { label: "\u4F26\u52C3\u6717\u7167\u660E", value: "Rembrandt Lighting" },
      { label: "\u5206\u4F53\u7167\u660E", value: "Split Lighting" },
      { label: "\u5E72\u51C0\u7684\u80CC\u666F\u8D8B\u52BF", value: "Clean background trending" },
      { label: "\u8FB9\u7F18\u706F", value: "Rim lights" },
      { label: "\u5168\u5C40\u7167\u660E", value: "Global illuminations" },
      { label: "\u9713\u8679\u706F\u51B7\u5149", value: "Neon cold lighting" },
      { label: "\u5F3A\u5149", value: "Hard lighting" },
      { label: "\u6591\u9A73\u5149\u7EBF", value: "Dappled Light" },
      { label: "\u620F\u5267\u5316\u706F\u5149", value: "Dramatic Lighting" },
      { label: "\u53CC\u91CD\u5149\u6E90", value: "Dual Lighting" },
      { label: "\u5149\u6591", value: "Flare" },
      { label: "\u95EA\u5149\u7C89", value: "Glitter" },
      { label: "\u5F3A\u786C\u706F\u5149", value: "Hard Lighting" },
      { label: "\u591C\u5E97\u706F\u5149", value: "Nightclub Lighting" },
      { label: "\u5F69\u8679\u706B\u82B1", value: "Rainbow Sparks" }
    ];
    this.styleType1List = [
      { label: "\u4E1C\u65B9\u5C71\u6C34\u753B", value: "Tradition Chinese Ink Painting" },
      { label: "\u6D6E\u4E16\u7ED8", value: "Japanese Ukiyo-e" },
      { label: "\u65E5\u672C\u6F2B\u753B\u98CE\u683C", value: "Japanese comics/manga" },
      { label: "\u7AE5\u8BDD\u6545\u4E8B\u4E66\u63D2\u56FE\u98CE\u683C", value: "Stock illustration style" },
      { label: "\u68A6\u5DE5\u5382\u52A8\u897F\u98CE\u683C", value: "CGSociety" },
      { label: "\u68A6\u5DE5\u5382\u5F71\u4E1A", value: "DreamWorks Pictures" },
      { label: "\u76AE\u514B\u65AF", value: "Pixar" },
      { label: "\u65F6\u5C1A", value: "Fashion" },
      { label: "\u65E5\u672C\u6D77\u62A5\u98CE\u683C", value: "Poster of Japanese graphic design" },
      { label: "90\u5E74\u4EE3\u7535\u89C6\u6E38\u620F", value: "90s video game" },
      { label: "\u6CD5\u56FD\u827A\u672F", value: "French art" },
      { label: "\u5305\u8C6A\u65AF", value: "Bauhaus" },
      { label: "\u65E5\u672C\u52A8\u753B\u7247", value: "Anime" },
      { label: "\u50CF\u7D20\u753B", value: "\u30C9\u30C3\u30C8\u7D75 and Pixel Art" },
      { label: "\u53E4\u5178\u98CE\uFF0C18-19\u4E16\u7EAA", value: "Vintage" },
      { label: "\u9ED1\u767D\u7535\u5F71\u65F6\u671F", value: "Pulp Noir" },
      { label: "\u4E61\u6751\u98CE\u683C", value: "Country style" },
      { label: "\u62BD\u8C61\u98CE", value: "Abstract" },
      { label: "Riso\u5370\u5237\u98CE", value: "Risograph" },
      { label: "\u8BBE\u8BA1\u98CE", value: "Graphic" },
      { label: "\u58A8\u6C34\u6E32\u67D3", value: "Ink render" },
      { label: "\u6C11\u65CF\u827A\u672F", value: "Ethnic Art" },
      { label: "\u590D\u53E4 \u9ED1\u6697", value: "Retro dark vintage" },
      { label: "\u56FD\u98CE", value: "Tradition Chinese Ink Painting style" },
      { label: "\u84B8\u6C7D\u670B\u514B", value: "Steampunk" },
      { label: "\u7535\u5F71\u6444\u5F71\u98CE\u683C", value: "Film photography" },
      { label: "\u6982\u5FF5\u827A\u672F", value: "Concept art" },
      { label: "\u526A\u8F91", value: "Montage" },
      { label: "\u5145\u6EE1\u7EC6\u8282", value: "Full details" },
      { label: "\u54E5\u7279\u5F0F\u9ED1\u6697", value: "Gothic gloomy" },
      { label: "\u5199\u5B9E\u4E3B\u4E49", value: "Realism" },
      { label: "\u9ED1\u767D", value: "Black and white" },
      { label: "\u7EDF\u4E00\u521B\u4F5C", value: "Unity Creations" },
      { label: "\u5DF4\u6D1B\u514B\u65F6\u671F", value: "Baroque" },
      { label: "\u5370\u8C61\u6D3E", value: "Impressionism" },
      { label: "\u65B0\u827A\u672F\u98CE\u683C", value: "Art Nouveau" },
      { label: "\u65B0\u827A\u672F", value: "Rococo" },
      { label: "\u827E\u5FB7\u91CC\u5B89\xB7\u591A\u8BFA\u4F11\uFF08\u6CB9\u753B\uFF09", value: "Adrian Donohue" },
      { label: "\u827E\u5FB7\u91CC\u5B89\xB7\u6258\u7C73\u5C3C\uFF08\u7EBF\u6027\u4EBA\u7269\uFF09", value: "Adrian Tomine" },
      { label: "\u5409\u7530\u660E\u5F66\uFF08\u539A\u6D82\u4EBA\u7269\uFF09", value: "Akihiko Yoshida" },
      { label: "\u9E1F\u5C71\u660E\uFF08\u4E03\u9F99\u73E0\uFF09", value: "Akira Toiyama" },
      { label: "\u963F\u65B9\u65AF\xB7\u7A46\u67E5\uFF08\u9C9C\u8273\u7EBF\u6027\uFF09", value: "Alphonse Mucha" },
      { label: "\u8521\u56FD\u5F3A\uFF08\u7206\u70B8\u827A\u672F\uFF09", value: "Cai Guo-Qiang" },
      { label: "\u300A\u661F\u7403\u5927\u6218\u300B", value: "Drew Struzan" },
      { label: "\u8FBE\u8FBE\u4E3B\u4E49\u3001\u6784\u6210\u4E3B\u4E49", value: "Hans Arp" },
      { label: "\u67D4\u548C\u4EBA\u7269", value: "Ilya Kuvshiov" },
      { label: "\u68A6\u5E7B\u6D41\u7545", value: "James Jean" },
      { label: "\u8FF7\u5E7B\u3001\u4ED9\u5973\u3001\u5361\u901A", value: "Jasmine Becket-Griffith" },
      { label: "\u7F8E\u5F0F\u4EBA\u7269", value: "Jean Giraud" },
      { label: "\u5C40\u90E8\u89E3\u5256", value: "Partial anatomy" },
      { label: "\u5F69\u58A8\u7EB8\u672C", value: "Color ink on paper" },
      { label: "\u6D82\u9E26", value: "Doodle" },
      { label: "\u4F0F\u5C3C\u5951\u624B\u7A3F", value: "Voynich manuscript" },
      { label: "\u4E66\u9875", value: "Book page" },
      { label: "\u771F\u5B9E\u7684", value: "Realistic" },
      { label: "3D\u98CE\u683C", value: "3D" },
      { label: "\u590D\u6742\u7684", value: "Sophisticated" },
      { label: "\u771F\u5B9E\u611F", value: "Photoreal" },
      { label: "\u89D2\u8272\u6982\u5FF5\u827A\u672F", value: "Character concept art" }
    ];
    this.styleType2List = [
      { label: "\u6587\u827A\u590D\u5174", value: "Renaissance" },
      { label: "\u91CE\u517D\u6D3E", value: "Fauvism" },
      { label: "\u7ACB\u4F53\u6D3E", value: "Cubism" },
      { label: "\u62BD\u8C61\u8868\u73B0\u4E3B\u4E49", value: "Abstract Art" },
      { label: "\u8D85\u73B0\u5B9E\u4E3B\u4E49", value: "Surrealism" },
      { label: "\u6B27\u666E\u827A\u672F/\u5149\u6548\u5E94\u827A\u672F", value: "OP Art /Optical Art" },
      { label: "\u7EF4\u591A\u5229\u4E9A\u65F6\u4EE3", value: "Victorian" },
      { label: "\u672A\u6765\u4E3B\u4E49", value: "Futuristic" },
      { label: "\u6781\u7B80\u4E3B\u4E49", value: "Minimalist" },
      { label: "\u7C97\u72B7\u4E3B\u4E49", value: "Brutalist" },
      { label: "\u5EFA\u6784\u4E3B\u4E49", value: "Constructivist" },
      { label: "\u65F7\u91CE\u4E4B\u606F", value: "BOTW" },
      { label: "\u661F\u9645\u6218\u7532", value: "Warframe" },
      { label: "\u5B9D\u53EF\u68A6", value: "Pok\xE9mon" },
      { label: "Apex\u82F1\u96C4", value: "APEX" },
      { label: "\u4E0A\u53E4\u5377\u8F74", value: "The Elder Scrolls" },
      { label: "\u9B42\u7CFB\u6E38\u620F", value: "From Software" },
      { label: "\u5E95\u7279\u5F8B:\u53D8\u4EBA", value: "Detroit: Become Human" },
      { label: "\u5251\u4E0E\u8FDC\u5F81", value: "AFK Arena" },
      { label: "\u8DD1\u8DD1\u59DC\u997C\u4EBA", value: "CookieRun Kingdom and \uCFE0\uD0A4\uB7F0" },
      { label: "\u82F1\u96C4\u8054\u76DF", value: "League of Legends" },
      { label: "Jojo\u7684\u5947\u5999\u5192\u9669", value: "Jojo\u2019s Bizarre Adventure" },
      { label: "\u65B0\u6D77\u8BDA", value: "Makoto Shinkai" },
      { label: "\u526F\u5C9B\u6210\u8BB0", value: "Soejima Shigenori" },
      { label: "\u5C71\u7530\u7AE0\u535A", value: "Yamada Akihiro" },
      { label: "\u516D\u4E03\u8D28", value: "Munashichi" },
      { label: "\u6C34\u5F69\u513F\u7AE5\u63D2\u753B", value: "Watercolor Children\u2019s Illustration" },
      { label: "\u5409\u535C\u529B\u98CE\u683C", value: "Ghibli Studio" },
      { label: "\u5F69\u8272\u73BB\u7483\u7A97", value: "Stained Glass Window" },
      { label: "\u6C34\u58A8\u63D2\u56FE", value: "Ink Illustration" },
      { label: "\u5BAB\u5D0E\u9A8F\u98CE\u683C", value: "Miyazaki Hayao Style" },
      { label: "\u68B5\u9AD8", value: "Vincent Van Gogh" },
      { label: "\u8FBE\u82AC\u5947", value: "Leonardo Da Vinci" },
      { label: "\u6F2B\u753B", value: "Manga" },
      { label: "\u70B9\u5F69\u6D3E", value: "Pointillism" },
      { label: "\u514B\u52B3\u5FB7\u83AB\u5948", value: "Claude Monet" },
      { label: "\u7ED7\u7F1D\u827A\u672F", value: "Quilted Art" },
      { label: "\u5305\u8C6A\u65AF", value: "Johannes Itten" },
      { label: "\u79D1\u5E7B\u3001\u5947\u5E7B\u3001\u6CB9\u753B", value: "John Haris" },
      { label: "\u5E72\u51C0\u3001\u7B80\u7EA6", value: "Jon Klassen" },
      { label: "\u4F0A\u85E4\u6DA6\u4E8C \u6050\u6016\u6F2B\u753B", value: "Junji Ito" },
      { label: "\u65E5\u672C\u6F2B\u753B\u5BB6\u300A\u58F0\u4E4B\u5F62\u300B", value: "Koe No Katachi" },
      { label: "\u624B\u51A2\u6CBB\u866B\u300A\u5343\u4E0E\u5343\u5BFB\u300B", value: "Qsamu Tezuka" },
      { label: "\u5947\u5E7B\u3001\u5149\u5B66\u5E7B\u8C61", value: "Rob Gonsalves" },
      { label: "\u51E0\u4F55\u6982\u5FF5\u827A\u672F", value: "Sol LeWitt" },
      { label: "\u7EBF\u6761\u6D41\u7545\u3001\u7CBE\u7F8E", value: "Yusuke Murata" },
      { label: "\u6570\u5B57\u6DF7\u5408\u5A92\u4F53\u827A\u672F", value: "Antonio Mora" },
      { label: "\u7EC6\u817B\u3001\u673A\u68B0\u8BBE\u8BA1", value: "Yoji Shinkawa" },
      { label: "\u56FD\u5BB6\u5730\u7406", value: "National Geographic" },
      { label: "\u8D85\u5199\u5B9E\u4E3B\u4E49", value: "Hyperrealism" },
      { label: "\u7535\u5F71\u822C\u7684", value: "Cinematic" },
      { label: "\u5EFA\u7B51\u7D20\u63CF", value: "Architectural Sketching" },
      { label: "\u5BF9\u79F0\u8096\u50CF", value: "Symmetrical Portrait" },
      { label: "\u6E05\u6670\u7684\u9762\u90E8\u7279\u5F81", value: "Clear Facial Features" },
      { label: "\u5BA4\u5185\u8BBE\u8BA1", value: "Interior Design" },
      { label: "\u6B66\u5668\u8BBE\u8BA1", value: "Weapon Design" },
      { label: "\u6B21\u8868\u9762\u6563\u5C04", value: "Subsurface Scattering" },
      { label: "\u6E38\u620F\u573A\u666F\u56FE", value: "Game Scene Graph" }
    ];
  }
  mounted() {
  }
  // 打开弹窗
  open() {
    return __async(this, null, function* () {
      this.dialogVisible = true;
    });
  }
  // 关闭弹窗
  close() {
    this.dialogVisible = false;
    this.midjourney = "";
    this.controller = new FormController();
  }
  copymidjourney(type) {
    copyText.copyText(type);
  }
  // 带入
  importmidjourney() {
    this.dialogVisible = false;
    this.$emit("importGeneration", this.midjourney);
  }
  submit() {
    return __async(this, null, function* () {
      try {
        this.midjourney = "";
        for (const key in this.controller) {
          if (key != "_status") {
            this.midjourney += this.controller[key] + " ";
          }
        }
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
      }
    });
  }
};
SpellGenerationDialog = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit
    }
  })
], SpellGenerationDialog);
export {
  SpellGenerationDialog as default
};
