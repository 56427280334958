"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Component, Vue } from "vue-property-decorator";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import { APIDataSource } from "@/components/grid/data-source";
import Layout from "@/components/Layout.vue";
let OrderCenter = class extends Vue {
  constructor() {
    super(...arguments);
    // 选中的列表行对象数组
    this.paymentMethodsMap = {
      KV_COIN: "\u9CB8\u8C46\u652F\u4ED8",
      ALIPAY: "\u652F\u4ED8\u5B9D",
      WECHAT: "\u5FAE\u4FE1\u652F\u4ED8"
    };
    this.orderStatusMap = {
      UNPAID: "\u672A\u4ED8\u6B3E",
      COMPLETED: "\u5DF2\u5B8C\u6210"
    };
    this.gridController = new GridController(
      new APIDataSource(`api://base.auth/v1/orders`)
    );
  }
};
OrderCenter = __decorateClass([
  Component({
    components: {
      Layout,
      UiGrid
    }
  })
], OrderCenter);
export {
  OrderCenter as default
};
