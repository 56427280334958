"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue, Prop } from "vue-property-decorator";
import UiForm, {
  UiFormController,
  UiFormStatus
} from "@/components/form/UiForm.vue";
import API from "@ym-framework/api-utils";
import { throwErrorApi } from "@/shared/api/throw-error/throw-error.api";
import UiSubmit from "@/components/form/UiSubmit.vue";
export class FormController extends UiFormController {
  constructor() {
    super(...arguments);
    this.parentId = "";
    this.name = "";
    this.icon = "";
  }
}
let Editcategory = class extends Vue {
  constructor() {
    super(...arguments);
    this.controller = new FormController();
    this.fileList = [];
  }
  // secondary = { root: "", Primary: "" };
  // secondcategory: any[] = [];
  // firstcategory: any[] = [];
  // options = [
  //   {
  //     value: "ROOT",
  //     label: "Root",
  //   },
  // ];
  mounted() {
    this.controller.parentId = this.editData.parentId;
    this.controller.name = this.editData.name;
    if (this.editData.icon) {
      this.fileList.push({
        name: "icon",
        url: this.editData.icon
      });
    }
  }
  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      let readerResult = "";
      reader.readAsDataURL(file);
      reader.onload = function() {
        readerResult = reader.result;
      };
      reader.onerror = function(error) {
        reject(error);
      };
      reader.onloadend = function() {
        resolve(readerResult);
      };
    });
  }
  changeIcon(file, fileList) {
    if (fileList.length > 1) {
      fileList.splice(0, 1);
    }
    this.fileToBase64(file.raw).then((base64) => {
      this.controller.icon = base64;
    });
  }
  removeIcon(file, fileList) {
    this.controller.icon = "";
  }
  submit() {
    return __async(this, null, function* () {
      try {
        this.controller.status = UiFormStatus.LOADING;
        if (this.editData.type == "add") {
          yield API.post(`api://base.admin/admin/v1/category`, this.controller);
        } else {
          yield API.put(
            `api://base.admin/admin/v1/category/${this.editData.id}`,
            this.controller
          );
        }
        this.goBack();
      } catch (error) {
        throwErrorApi.throwError(error);
      } finally {
        this.controller.status = UiFormStatus.COMPLETE;
      }
    });
  }
  goBack() {
    this.$emit("exitEdit");
  }
};
__decorateClass([
  Prop()
], Editcategory.prototype, "editData", 2);
__decorateClass([
  Prop()
], Editcategory.prototype, "category", 2);
Editcategory = __decorateClass([
  Component({
    components: {
      UiForm,
      UiSubmit
    }
  })
], Editcategory);
export {
  Editcategory as default
};
