"use strict";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
const accountInfo = {
  namespaced: true,
  state: {
    session: {
      model: null,
      account: "",
      accountId: "",
      orgId: "",
      userId: "",
      powers: [],
      extension: null,
      ip: null,
      device: null,
      area: null,
      labels: [],
      formal: true,
      ghost: false,
      token: ""
    },
    // credits: {
    //   code: "",
    //   used: 0,
    //   initialTotal: 0,
    //   total: 0,
    //   quotaType: "",
    //   enhances: []
    // }
    credits: {
      freeMessageCreditQuota: {
        usedMessageCreditQuota: 0,
        messageCreditQuota: 20
      },
      normalRemainingMessageCreditQuota: 0,
      totalRemainingMessageCredit: 20
    },
    isSigned: false
  },
  mutations: {
    updateSession(state, newdata) {
      state.session = newdata;
    },
    updateCredits(state, newdata) {
      state.credits = newdata;
    },
    updateSigned(state, newdata) {
      state.isSigned = newdata;
    }
  }
};
const templateInfo = {
  namespaced: true,
  state: {
    pageinfo: {
      title: "",
      introduce: ""
    },
    templateOptions: {
      gptmodel: "",
      language: "",
      theme: "light"
    }
  },
  mutations: {
    updatePageinfo(state, newdata) {
      state.pageinfo = newdata;
    },
    updateTemplateOptions(state, newdata) {
      for (let key in newdata) {
        state.templateOptions[key] = newdata[key];
      }
    },
    updateTheme(state, newTheme) {
      state.templateOptions.theme = newTheme;
      localStorage.setItem("theme", newTheme);
    }
  }
};
const gptinstructionInfo = {
  namespaced: true,
  state: {
    instructionInfo: ""
  },
  mutations: {
    updateInstructionInfo(state, newdata) {
      state.instructionInfo = newdata;
    }
  }
};
const paintingInfo = {
  namespaced: true,
  state: {
    session: {
      command: "",
      commands: "",
      createdTime: "",
      executeCommand: "",
      girdImageUrl: "",
      id: "",
      model: "",
      nextActions: [],
      progress: 0,
      prompt: "",
      singleImageUrls: [],
      status: "",
      type: "",
      updatedTime: ""
    },
    draw: {
      command: "",
      commands: "",
      createdTime: "",
      executeCommand: "",
      girdImageUrl: "",
      id: "",
      model: "",
      nextActions: [],
      progress: 0,
      prompt: "",
      singleImageUrls: [],
      status: "",
      type: "",
      updatedTime: ""
    }
  },
  mutations: {
    updatePaintingInfo(state, newdata) {
      state.session = newdata;
    },
    updateDrawInfo(state, newdata) {
      state.draw = newdata;
    }
  }
};
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    accountInfo,
    templateInfo,
    gptinstructionInfo,
    paintingInfo
  },
  plugins: [
    createPersistedState({
      key: "user",
      // 存数据的key名   自定义的  要有语义化
      paths: ["accountInfo", "gptinstructionInfo", "paintingInfo"]
      // 要把那些模块加入缓存
    })
  ]
});
