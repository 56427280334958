"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import UiGrid, { GridController } from "@/components/grid/UiGrid.vue";
import { APIDataSource } from "@/components/grid/data-source";
import API from "@ym-framework/api-utils";
import Layout from "@/components/Layout.vue";
import copyText from "@/utils/copyText";
let UserInvitation = class extends Vue {
  constructor() {
    super(...arguments);
    this.gridController = new GridController(
      new APIDataSource(`api://base.auth/v1/invite-friends/history`),
      {
        size: 10
      }
    );
    this.invitationLink = "";
    this.invitationCode = "";
    // 是否被赠送
    this.isInviteGiftMessageCredits = false;
    this.inviteGiftMessageCredits = 0;
  }
  mounted() {
    return __async(this, null, function* () {
      this.invitationLink = (yield API.get(`api://base.auth/v1/invite-friends-link`)).data;
      this.invitationLink = this.invitationLink.replace("/invite-register", "");
      this.invitationCode = this.invitationLink.slice(
        this.invitationLink.indexOf("=") + 1
      );
      const res = (yield API.get(`api://base.auth/v1/gift-message-credits`)).data;
      this.isInviteGiftMessageCredits = res.isInviteGiftMessageCredits;
      this.inviteGiftMessageCredits = res.inviteGiftMessageCredits;
    });
  }
  // 赠送额度
  // 复制
  copyLink(type) {
    copyText.copyText(type);
  }
};
UserInvitation = __decorateClass([
  Component({
    components: {
      Layout,
      UiGrid
    }
  })
], UserInvitation);
export {
  UserInvitation as default
};
