"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Component, Vue } from "vue-property-decorator";
import API from "@ym-framework/api-utils";
import markdownBox from "../views/gptsession/views/components/markdownBox.vue";
let VersionUpdatingDialog = class extends Vue {
  constructor() {
    super(...arguments);
    this.isShowVersionUpdating = false;
    // versionUpdatingContent =
    //   "### 新功能\n- **用户界面改进**：优化了用户界面，提升了用户体验。\n- **多语言支持**：新增了对5种新语言的支持，包括中文、西班牙语、德语、法语和日语。\n- **云同步功能**：允许用户在不同设备间同步数据。\n\n### 修复的Bug\n- 修复了在特定条件下，应用可能崩溃的问题。\n- 修复了用户在夜间模式下遇到的显示问题。\n- 修复了在高负载下的性能延迟问题。\n\n### 性能优化\n- 对核心算法进行了优化，提高了处理速度。\n- 减少了应用的内存占用。\n\n### 安全性改进\n- 更新了加密协议，增强了数据传输的安全性。";
    this.versionUpdatingTime = "";
    this.versionUpdatingContent = "";
    this.versionNumber = "";
  }
  mounted() {
    return __async(this, null, function* () {
      const _token = localStorage.getItem("token");
      if (_token) {
        this.versionUpdate();
      }
    });
  }
  // closeVersionUpdatingDialog() {
  //   const div = document.querySelectorAll(".versionUpdating");
  //   //@ts-ignore
  //   div[0].style.opacity = 0;
  //   setTimeout(() => {
  //     this.isShowVersionUpdating = false;
  //   }, 300);
  // }
  versionupdatingSubmit() {
    try {
      const main = require("../../package.json");
      const version = main.version.replace(/\+/g, ".");
      API.post(`api://base.auth/v1/acknowledge-version-update`, {
        versionNumber: version
      });
    } catch (error) {
    } finally {
      const div = document.querySelectorAll(".versionUpdating");
      div[0].style.opacity = 0;
      setTimeout(() => {
        this.isShowVersionUpdating = false;
      }, 300);
    }
  }
  versionUpdate() {
    return __async(this, null, function* () {
      try {
        const main = require("../../package.json");
        const version = main.version.replace(/\+/g, ".");
        const res = (yield API.post(`api://base.auth/v1/check-version-update`, {
          versionNumber: version
        })).data;
        if (res.content) {
          this.versionUpdatingContent = res.content;
          this.versionUpdatingTime = res.updatedTime;
          this.versionNumber = res.versionNumber;
          this.isShowVersionUpdating = true;
        }
      } catch (e) {
      }
    });
  }
};
VersionUpdatingDialog = __decorateClass([
  Component({
    components: { markdownBox }
  })
], VersionUpdatingDialog);
export {
  VersionUpdatingDialog as default
};
