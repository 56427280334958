"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import API from "@ym-framework/api-utils";
class AccountInfoApi {
  /// 获取用户信息
  getAccountInfo() {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v1/session`
      );
      return response.data;
    });
  }
  // 获取用户套餐消息额度
  // async getCredits() {
  // 	const response = await API.get(
  // 		`api://base.auth/v1/message-credits`
  // 	)
  // 	return response.data
  // }
  getCredits() {
    return __async(this, null, function* () {
      const response = yield API.get(
        `api://base.auth/v2/message-credits`
      );
      return response.data;
    });
  }
}
export const accountInfoAPI = new AccountInfoApi();
